<template>
  <div :key="'listing-add-' + updateHTML">
    <ObjectSelection
      v-if="
        objectSelectionOpen &&
        chosenLinkedDeal.objects &&
        chosenLinkedDeal.objects.length > 0
      "
      :project="chosenLinkedDeal"
      :position="position"
      @closed="objectSelectionOpen = false"
      @objectSelected="chooseObject"
    />
    <Sidepanel
      style="z-index: 11"
      @saveResponse="handleNewDeal"
      :no-refresh="true"
      :from-add-listing="true"
      v-if="projectAddOpen"
      @closed="closeProjectAdd"
    />
    <div
      class="sticky"
      style="
        display: flex;
        height: 64px;
        width: 100%;
        z-index: 10;
        padding-left: 156px;
        align-items: center;
      "
    >
      <div
        class="add-button"
        @click="saveListing(form, true)"
        style="margin-right: 12px"
      >
        <div
          style="position: relative; width: 30px; height: 30px"
          v-if="isLoading"
          class="loader"
        ></div>
        <img
          v-if="!isLoading"
          style="margin-right: 9px"
          alt=""
          src="../../assets/images/checkmarkCircled.svg"
        />
        <p v-if="!isLoading">{{ $t("SaveAndExit") }}</p>
      </div>
      <div
        class="cancel"
        @click="saveListing(form, false)"
        style="margin-right: 12px; cursor: pointer"
      >
        <div
          style="position: relative; width: 30px; height: 30px"
          v-if="isLoading"
          class="loader"
        ></div>
        <p v-if="!isLoading">{{ $t("Save") }}</p>
      </div>

      <button
        @click="cancel()"
        class="cancel padding-top"
        style="margin-right: 16px"
      >
        {{ $t("Cancel") }}
      </button>
      <!--  <div v-if="!user.config" style="margin-right: 16px">•</div>
      <button
        v-if="!user.config"
        class="outlined-small-button content-normal"
        style="height: 40px; margin-right: 8px"
      >
        <img
          style="padding-top: 2px"
          alt=""
          src="../../assets/images/search.svg"
        />
        <span>{{ $t("Preview") }}</span>
      </button>
      <button
        v-if="!user.config"
        class="outlined-small-button content-normal"
        style="height: 40px; margin-right: 16px"
      >
        {{ $t("Duplicate") }}
      </button> -->
      <div v-if="edit && hasAccess(form, 'listings', 'delete')" style="margin-right: 16px">•</div>
      <div v-if="edit && hasAccess(form, 'listings', 'delete')">
        <button
          @click="openDeleteModal"
          class="delete-button"
          color="blue darken-1"
          text
        >
          <img
            style="padding-right: 8px"
            src="../../assets/images/TrashBin.svg"
            alt=""
          />
          {{ $t("Delete") }}
        </button>
      </div>
    </div>
    <div class="header" style="margin-top: 129px; padding-top: 0">
      <h3 v-if="!edit">{{ $t("AddNewListing") }}</h3>
      <h3 v-if="edit">{{ $t("EditListing") }}</h3>
      <p
        @click="openStatusDropdown(form)"
        class="statusLabel content-bold"
        id="statusLabel"
        v-bind:class="{
          clickable: edit,
          'draft-label ': form.status === 'draft',
          'active-label ': form.status === 'active',
          'booked-label': form.status === 'booked',
          'sold-label ': form.status === 'sold',
          'archived-label': form.status === 'archived',
          'rented-label': form.status === 'rented',
          'expired-label': form.status === 'expired',
        }"
      >
        {{ $lfMapper.status(form.status) }}
      </p>

      <v-tooltip bottom right :key="bookingUpdater">
        <template v-slot:activator="{ on, attrs }">
          <p
            v-bind="attrs"
            v-on="on"
            v-if="form.status === 'booked'"
            class="booked-label booked-info-label"
          >
            <img
              style="filter: invert(1)"
              src="../../assets/images/info.svg"
              alt=""
              @click="openBookingDetailsModal(form)"
            />
          </p>
        </template>
        <span>{{ $t("BookingDetails") }}</span>
      </v-tooltip>

      <p
        style="margin-left: 8px; cursor: pointer"
        v-if="
          form.status === 'active' && form.dateAvailable && !form.activeUntil
        "
        class="content-small"
      >
        {{ $t("available") }} {{ formatDate(form.dateAvailable) }}
      </p>
      <p
        style="margin-left: 8px; cursor: pointer"
        @click="openBookingDetailsModal(form)"
        v-if="form.status === 'booked' && form.bookedUntil"
        class="content-small booking-until-date"
      >
        {{ $t("until") }} {{ formatDate(form.bookedUntil) }}
      </p>
      <date-pick
        :isDateDisabled="isFutureDate"
        @input="changeActiveUntil(form, $event)"
        v-if="form.status === 'active' && form.activeUntil"
        style="display: flex"
      >
        <template v-slot:default="{ toggle }">
          <p
            @click.stop="toggle"
            style="margin-left: 8px; cursor: pointer"
            v-if="form.status === 'active' && form.activeUntil"
            class="content-small"
          >
            {{ $t("until") }} {{ form.activeUntil }}
          </p>
        </template>
      </date-pick>
    </div>
    <div class="content">
      <div class="add-edit-outputs-wrapper">
        <AddEditOutputs :listing="form" />
      </div>
      <div class="section-row">
        <v-row class="subheading">
          <div class="circle-number" style="padding-top: 1px">1</div>
          <h5>{{ $t("AddProjectAndObjectDetails") }}</h5></v-row
        >
        <v-row
          v-if="(edit && deal) || (deal && addToProject)"
          class="autocomplete-frame"
          style="width: 100%"
        >
          <v-row class="deal-name">
            {{ deal.name }}
          </v-row>
          <v-row style="gap: 12px;">
            <div class="deal-name-item" @click="openDeal(deal)">View</div>
            <div class="deal-name-item" @click="openListingModal(deal)">{{ $t("LinkWithOtherProject")}}</div>
          </v-row>

          <!--          <v-row
            v-if="
              form.dealType === 'rent' || form.dealType === 'short_term_rent'
            "
            @click="setShortTermRent"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            "
          >
            <Checkbox :checked="isShortTermRent"> </Checkbox>
            <p class="short-term-rent-option-text">
              <b>{{ $t("ShortTermRent") }}</b>
            </p>
          </v-row>-->
        </v-row>
        <v-row
          v-if="!edit && !addToProject"
          class="autocomplete-frame"
          style="width: 100%"
        >
          <v-row
            v-if="$route.params.referenceDealId && $route.params.project._id"
            class="deal-name"
          >
            {{ chosenLinkedDealName }}
          </v-row>
          <v-row
            v-if="!$route.params.referenceDealId && (!user.config || (user.config && user.access?.features?.modules?.projects?.enabled))"
            class="autocomplete-row"
          >
            <v-row class="required">
              <h6 class="content-bold">{{ $t("LinkListing") }}</h6>

            </v-row>
            <v-row
              v-if="listingLink == 'project'"
              style="
                justify-content: flex-start;
                align-items: center;
                gap: 32px;
                margin-top: 16px;
              "
            >
              <!--                class="autocomplete-item"-->
              <Autocomplete
                v-click-outside="closeMenu"
                :class="'autocomplete-item ' + (isErrorDeal ? 'not-valid' : '')"
                :placeholder="$t('AddProject')"
                :items="[]"
                :items-per-page="25"
                search-key="name"
                display-key="name"
                :searching="true"
                :close-menu="closeDealMenu"
                @resultChosen="setRelatedDeal"
                @addNew="openProjectAdd"
                @removeResult="clearDeal"
                :written-text="chosenLinkedDealName"
                :error-key="isErrorDeal"
              />
              <div
                v-if="chosenLinkedDeal"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  gap: 4px;
                  align-items: center;
                "
              >
                <p>{{ $t("ProjectType") }}:</p>
                <p
                  v-bind:class="{
                    'content-small-green green-font':
                      chosenLinkedDeal.dealType == 'Sale',
                    'content-small-pink pink-font':
                      chosenLinkedDeal.dealType == 'Lease out' ||
                      chosenLinkedDeal.dealType == 'LeaseOut',
                  }"
                >
                  {{ formatDealType() }}
                </p>
              </div>
            </v-row>
          </v-row>
          <!--          <v-row
            v-if="form.dealType === 'rent' && !user.config"
            @click="setShortTermRent"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            "
          >
            <Checkbox :checked="isShortTermRent"> </Checkbox>
            <p class="short-term-rent-option-text">
              <b>{{ $t("ShortTermRent") }}</b>
            </p>
          </v-row>-->
          <v-row v-if="user.config && !user.access?.features?.modules?.projects?.enabled" style="width: 408px" class="required">
            <img src="../../assets/images/objects.svg" alt="" />
            <h6 class="content-bold">{{ $t("AddObject") }}</h6>
            <v-row
              class="autocomplete-frame-address"
              style="width: 100%; align-items: center; z-index: 1"
            >
              <div class="object-address-container">
                <div
                  @click="focusInput"
                  @focusout="unfocusInput"
                  class="address-div"
                  @keypress="objectSelectionOpen = false"
                  id="InAadressDiv"
                  v-bind:class="{
                    'address-div': isErrorAddress === false,
                    'address-div gray-border': isErrorAddress === true,
                  }"
                ></div>
              </div>
            </v-row>
          </v-row>
        </v-row>
        <v-row
          v-if="(user.config && !user.access?.features?.modules?.projects?.enabled) && addressSelected"
          class="subheading required"
        >
          <h6>{{ $t("ListingType") }}</h6></v-row
        >
        <v-row v-if="addressSelected || edit" class="box-wrapper">
          <button
            v-for="dealType in dealTypes"
            :key="dealType.name"
            item-text="name"
            item-value="value"
            @click="
              form.dealType = dealType.value;
              chooseBox1(dealType.value);
            "
            v-bind:class="{
              'big-box1 icon-box': !dealType.isChosen,
              'big-box1-white icon-box active-box': dealType.isChosen,
            }"
            class="big-box1 icon-box"
          >
            <v-row style="align-items: center">
              <img
                v-bind:class="{
                  'big-box2-image': !dealType.isChosen,
                  'big-box2-image-white ': dealType.isChosen,
                }"
                class="big-box3-image"
                v-bind:src="
                  require('../../assets/images/' + dealType.name + '.svg')
                "
                alt=""
              />
              {{ dealType.name }}
            </v-row>
          </button>
        </v-row>
        <div v-if="edit || chosenLinkedDeal || addressSelected">
          <v-row class="subheading required">
            <h6>{{ $t("EstateType") }}</h6></v-row
          >
          <v-row class="box-wrapper">
            <button
              v-for="estateType in estateTypes"
              :name="'estate-type-' + estateType.value"
              :key="estateType.name"
              item-text="name"
              item-value="value"
              @click="
                form.estateType = estateType.value;
                chooseBox2(estateType.value);
              "
              v-bind:class="{
                'big-box2 icon-box': !estateType.isChosen,
                'big-box2-white icon-box active-box': estateType.isChosen,
              }"
              class="big-box2 icon-box"
            >
              <v-row style="align-items: center">
                <img
                  v-bind:class="{
                    'big-box2-image': !estateType.isChosen,
                    'big-box2-image-white': estateType.isChosen,
                  }"
                  class="big-box2-image"
                  v-bind:src="
                    require('../../assets/images/' + estateType.value + '.svg')
                  "
                  alt=""
                />
                {{ estateType.name }}
              </v-row>
            </button>
          </v-row>
          <v-row class="subheading"
            ><h6>{{ $t("Address") }}</h6></v-row
          >
          <v-row
            style="
              padding-top: 4px;
              justify-content: space-between;
              width: 100%;
              gap: 12px;
            "
          >
            <v-col class="house-numbers" style="max-width: 50%">
              <v-row>
                <AddressFinder
                  :object="form.address ? form.address : ''"
                  :placeholder="$t('Address')"
                  :default-address="formattedAddress"
                  :show-error="showErrors && !isFieldValid('object')"
                  :error-message="errors && errors.object ? errors.object : ''"
                  ref="addressFinder"
                  :isRequired="false"
                  @setObject="updateAddress"
                  @removeObject="removeAddressObject"
                />
                <!-- TODO address finder for generic types -->
              </v-row>
              <v-row>
                <Dropdown
                  :placeholder-text="$t('County')"
                  :custom-style="'width: 100%;height: 56px;z-index: 3;'"
                  :regular-size="true"
                  :disabled="countiesDisabled"
                  :key="countiesUpdater"
                  v-if="counties"
                  :items="counties"
                  :search-box="true"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedCounty"
                  @itemSelected="selectCounty"
                />
              </v-row>
              <v-row>
                <Dropdown
                  :custom-style="'width: 100%;font-size: 14px; line-height: 20px; height: 56px;z-index: 2;'"
                  :placeholder-text="$t('City')"
                  :regular-size="true"
                  :disabled="citiesDisabled"
                  :search-box="true"
                  :key="citiesUpdater"
                  :items="cities"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedCity"
                  @itemSelected="selectCity"
                />
              </v-row>
              <v-row v-if="form.addressType !== 'generic'">
                <Dropdown
                  :custom-style="'width: 100%;height: 56px;'"
                  :placeholder-text="$t('District')"
                  :regular-size="true"
                  :disabled="districtsDisabled"
                  :search-box="true"
                  :key="districtsUpdater + 0.2"
                  :items="districts"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedDistrict"
                  @itemSelected="selectDistrict"
                />
              </v-row>
              <v-row>
                <BaseInput
                  id="street"
                  v-model="form.street"
                  :placeholder="$t('Street')"
                />
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="house-number"
                  v-model="form.houseNumber"
                  :placeholder="$t('HouseNumber')"
                />
                <v-checkbox
                  v-model="form.showHouseNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>

              <v-row
                v-if="
                  form.estateType == 'apartment' ||
                  form.estateType == 'house_part'
                "
                style="gap: 24px; align-items: center; flex-wrap: nowrap"
              >
                <BaseInput
                  id="apartment-number"
                  v-model="form.apartmentNumber"
                  :placeholder="$t('ApartmentNumber')"
                />
                <v-checkbox
                  v-model="form.showApartmentNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="cadastral-number"
                  v-model="form.cadastralNumber"
                  :placeholder="$t('CadastralNumber')"
                />
                <v-checkbox
                  v-model="form.showCadastralNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="openCadastalLink(form.cadastralNumber)"
                >
                  <img class="link-icon" src="../../assets/images/link.svg" />
                  <span style="margin-left: 10px; vertical-align: middle">{{
                    $t("OpenLandRegistry")
                  }}</span>
                </span>
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="estate-number"
                  v-model="form.estateNumber"
                  :placeholder="$t('EstateNumber')"
                  @change="numberInputFormatWholeNumber('estateNumber')"
                  :value-changed="valueChanged"
                />
                <v-checkbox
                  v-model="form.showEstateNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="
                    openELandRegisterLink(
                      form.cadastralNumber,
                      form.estateNumber
                    )
                  "
                >
                  <img class="link-icon" src="../../assets/images/link.svg" />
                  <span style="margin-left: 10px; vertical-align: middle">{{
                    $t("OpenELandRegister")
                  }}</span>
                </span>
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="ehr-number"
                  v-model="form.ehrNumber"
                  :placeholder="$t('EhrNumber')"
                  @change="numberInputFormatWholeNumber('ehrNumber')"
                  :value-changed="valueChanged"
                />
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="openEhrNumberLink(form.ehrNumber)"
                >
                  <img class="link-icon" src="../../assets/images/link.svg" />
                  <span style="margin-left: 10px; vertical-align: middle"
                    >{{ $t("Visit") }} EHR</span
                  >
                </span>
              </v-row>
            </v-col>
            <v-col>
              <div id="map">
                <GmapMap
                  :center="mapCoordinates"
                  :zoom="18"
                  map-type-id="terrain"
                  @click="setMarker"
                  style="width: 432px; height: 240px"
                >
                  <GmapMarker
                    id="marker"
                    :position="markerCoordinates"
                    :clickable="true"
                    :draggable="true"
                    @drag="updateMarker"
                  />
                </GmapMap>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="edit || chosenLinkedDeal || addressSelected">
        <div class="section">
          <v-row>
            <div class="circle-number">2</div>
            <h5>{{ $t("Price") }} & {{ $t("Availability") }}</h5>
          </v-row>
          <v-row style="display: flex; gap: 12px; margin-top: 32px">
            <BaseInput
              id="price"
              v-model="form.price"
              :format="'price'"
              :placeholder="$t('Price')"
              :required="true"
              :error="errors"
              icon-right="Price.svg"
              style="width: 210px"
              @click="activePriceCalculatingInput = 'price'"
              @change="priceCalculate"
              :value-changed="valueChanged"
            />
            <BaseInput
              id="square-meter-price"
              v-model="form.pricePerSquareMeter"
              :placeholder="$t('PricePerUnit')"
              icon-right="eur+m2.svg"
              @click="activePriceCalculatingInput = 'pricePerSquareMeter'"
              style="width: 210px"
              @change="priceCalculate"
              :value-changed="valueChanged"
            />
          </v-row>
          <v-row v-if="form.dealType !== 'sale'">
            <v-checkbox
              v-model="form.rentToOwn"
              :label="$t('RentToOwn')"
              color="black"
              class="chechkbox-parameters"
              style="padding-top: 18px; padding-left: 12px"
              hide-details
            ></v-checkbox>
          </v-row>
          <v-row v-if="form.dealType !== 'short_term_rent'">
            <v-checkbox
              v-if="form.dealType !== 'short_term_rent'"
              v-model="form.additionalInfo.tradable"
              :label="$t('Tradable')"
              color="black"
              hide-details
              class="chechkbox-parameters"
              style="padding-left: 12px; padding-top: 16px"
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="form.availableImmediately"
              :label="$t('ImmediatelyAvailable')"
              color="black"
              style="
                padding-left: 12px;
                padding-top: 16px;
                padding-bottom: 12px;
              "
              hide-details
            ></v-checkbox>
          </v-row>
          <v-row v-if="form.availableImmediately" style="width: 210px;">
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('DateAvailable')"
              :disabled="true"
              type="date"
            />
          </v-row>
          <v-row v-if="!form.availableImmediately" style="width: 210px;">
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('DateAvailable')"
              v-model="form.dateAvailable"
              type="date"
            />
          </v-row>
        </div>
        <div class="section">
          <v-row>
            <div class="circle-number">3</div>
            <h5>{{ $t("General") }}</h5></v-row
          >
          <v-row style="margin-top: 32px; gap: 32px">
            <v-col
              style="min-width: 432px; max-width: 432px"
              class="first-general-column"
            >
              <v-row class="floor-items">
                <BaseInput
                  v-if="form.estateType !== 'land'"
                  id="floors"
                  v-model="form.floors"
                  type="number"
                  :placeholder="$t('FloorCount')"
                  style="width: 300px"
                />

                <BaseInput
                  v-if="
                    form.estateType === 'garage' ||
                    form.estateType === 'apartment' ||
                    form.estateType === 'commercial' ||
                    form.estateType === 'house_part'
                  "
                  id="floor"
                  v-model="form.floor"
                  type="number"
                  :placeholder="$t('Floor')"
                  style="width: 120px"
                />
              </v-row>
              <v-row v-if="form.estateType !== 'land'">
                <BaseInput
                  id="build-year"
                  v-model="form.buildYear"
                  type="number"
                  :placeholder="$t('BuildYear')"
                  style="width: 432px"
                />
              </v-row>
              <v-row v-if="form.estateType !== 'land'">
                <BaseInput
                  id="general-surfaceArea"
                  v-model="form.generalSurfaceArea"
                  :placeholder="$t('GeneralSurfaceArea')"
                  style="width: 432px"
                  icon-right="SquareMeter.svg"
                  @click="activePriceCalculatingInput = 'surfaceArea'"
                  @change="priceCalculate"
                  :value-changed="valueChanged"
                />
              </v-row>
              <v-row
                v-if="form.estateType !== 'apartment'"
                style="gap: 12px; align-items: center; flex-wrap: nowrap"
              >
                <BaseInput
                  v-if="landAreaUnit === 'm2'"
                  id="land-area-m2"
                  v-model="form.landArea.m2"
                  :placeholder="$t('LandArea')"
                  style="width: 210px"
                  @click="activePriceCalculatingInput = 'm2'"
                  @change="priceCalculate"
                  :value-changed="valueChanged"
                />
                <BaseInput
                  v-if="landAreaUnit === 'ha'"
                  id="land-area-ha"
                  v-model="form.landArea.ha"
                  :placeholder="$t('LandArea')"
                  style="width: 210px"
                  @click="activePriceCalculatingInput = 'ha'"
                  @change="priceCalculate"
                  :value-changed="valueChanged"
                />
                <div class="input-wrapper" style="max-height: fit-content">
                  <v-radio-group row v-model="landAreaUnit">
                    <v-radio
                      :label="'m2'"
                      color="orange darken-3"
                      :value="'m2'"
                    ></v-radio>
                    <v-radio
                      :label="'ha'"
                      color="orange darken-3"
                      :value="'ha'"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-row>
              <v-row
                v-if="
                  form.estateType !== 'apartment' &&
                  form.estateType !== 'commercial' &&
                  form.estateType !== 'land'
                "
              >
                <BaseInput
                  id="living-area"
                  v-model="form.livingArea"
                  type="number"
                  :placeholder="$t('LivingArea')"
                  icon-right="SquareMeter.svg"
                  style="width: 432px"
                />
              </v-row>

              <v-row
                v-if="
                  form.estateType !== 'garage' && form.estateType !== 'land'
                "
              >
                <BaseInput
                  id="rooms"
                  v-model="form.rooms"
                  type="number"
                  :placeholder="$t('RoomCount')"
                  style="width: 432px"
                />
              </v-row>
              <v-row
                v-if="
                  form.estateType !== 'garage' && form.estateType !== 'land'
                "
              >
                <BaseInput
                  id="bedrooms"
                  v-model="form.bedrooms"
                  type="number"
                  :placeholder="$t('BedroomCount')"
                  style="width: 432px"
                />
              </v-row>
              <v-row
                v-if="
                  form.estateType !== 'garage' &&
                  form.estateType !== 'commercial' &&
                  form.estateType !== 'land'
                "
              >
                <BaseInput
                  id="bathroom-Count"
                  v-model="form.bathroomCount"
                  type="number"
                  :placeholder="$t('BathroomCount')"
                  style="width: 432px"
                />
              </v-row>

              <v-row v-if="form.estateType === 'apartment'">
                <v-checkbox
                  v-model="form.roomsSeparate"
                  id="rooms-separate"
                  :label="$t('RoomsSeparate')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-col>
            <v-col
              class="second-general-column"
              style="min-width: 432px; max-width: 432px"
            >
              <v-select
                id="condition-select"
                clearable
                clear-icon="mdi-close"
                v-if="form.estateType != 'land'"
                v-model="form.condition"
                :items="conditions"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('Condition')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>
              <v-select
                clearable
                v-if="
                  form.estateType != 'land' &&
                  form.estateType != 'apartment' &&
                  form.estateType != 'garage' &&
                  form.estateType != 'commercial'
                "
                v-model="form.buildingType"
                :items="buildingTypes"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('BuildingType')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>

              <v-select
                clearable
                v-if="
                  form.estateType != 'land' &&
                  form.estateType != 'apartment' &&
                  form.estateType != 'garage' &&
                  form.estateType != 'commercial'
                "
                v-model="form.readiness"
                :items="readinesses"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('Readiness')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>
              <v-select
                clearable
                v-model="form.ownershipForm"
                outlined
                :items="ownershipForms"
                item-text="name"
                item-value="value"
                :label="$t('FormOfOwnership')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>

              <v-select
                clearable
                v-if="form.estateType != 'land'"
                v-model="form.buildingMaterial"
                outlined
                :items="buildingMaterials"
                item-text="name"
                item-value="value"
                :label="$t('BuildingMaterial')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>
              <v-select
                clearable
                v-if="form.estateType != 'garage'"
                v-model="form.energyLabel"
                :items="energyLabels"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('EnergyLabel')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>

              <v-select
                clearable
                v-model="form.limitations"
                :items="limitations"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('Limitations')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>

              <v-row
                v-if="
                  form.estateType !== 'land' && form.estateType !== 'garage'
                "
              >
                <BaseInput
                  id="expenses-in-summer"
                  v-model="form.expensesInSummer"
                  type="number"
                  :placeholder="$t('ExpensesInSummer')"
                  @change="calculateExpenses"
                  icon-right="Price.svg"
                />
              </v-row>
              <v-row
                v-if="
                  form.estateType !== 'land' && form.estateType !== 'garage'
                "
              >
                <BaseInput
                  id="expenses-in-winter"
                  v-model="form.expensesInWinter"
                  type="number"
                  :placeholder="$t('ExpensesInWinter')"
                  @change="calculateExpenses"
                  icon-right="Price.svg"
                />
              </v-row>
              <v-select
                clearable
                v-if="form.estateType == 'land'"
                v-model="form.detailedPlan"
                :items="detailedPlans"
                item-text="name"
                item-value="value"
                outlined
                :label="$t('DetailPlan')"
                class="no-active"
                style="margin-bottom: 12px"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="form.estateType == 'land'" class="subheading"
            ><h6>{{ $t("LandPurposeOfUse") }}</h6></v-row
          >
          <v-row v-if="form.estateType == 'land'" style="gap: 32px">
            <v-col
              style="min-width: 432px; max-width: 432px"
              class="first-general-column"
            >
              <v-checkbox
                v-if="form.estateType == 'land'"
                v-model="form.commercialLand"
                :label="$t('CommercialLand')"
                color="black"
                hide-details
                style="padding-bottom: 12px; padding-top: 20px"
              ></v-checkbox>
              <v-checkbox
                v-if="form.estateType == 'land'"
                v-model="form.productionLand"
                :label="$t('ProductionLand')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-if="form.estateType == 'land'"
                v-model="form.industrialLand"
                :label="$t('IndustrialLand')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.forestLand"
                :label="$t('ForestLand')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
            </v-col>
            <v-col
              style="min-width: 432px; max-width: 432px"
              class="second-general-column"
            >
              <v-checkbox
                v-model="form.residentialLand"
                :label="$t('ResidentialLand')"
                color="black"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.profityieldingLand"
                :label="$t('ProfitYieldingLand')"
                color="black"
                hide-details
                style="padding-bottom: 12px; padding-top: 20px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.residentialLandApartment"
                :label="$t('ResidentialLandApartments')"
                value=""
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.otherUse"
                :label="$t('OtherUse')"
                color="black"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="form.estateType == 'commercial'" class="subheading"
            ><h6>{{ $t("CommercialPurposeOfUse") }}</h6></v-row
          >
          <v-row v-if="form.estateType == 'commercial'" style="gap: 32px">
            <v-col
              style="min-width: 432px; max-width: 432px"
              class="first-general-column"
            >
              <v-checkbox
                v-model="form.office"
                :label="$t('Office')"
                color="black"
                hide-details
                style="padding-bottom: 12px; padding-top: 20px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.tradeshop"
                :label="$t('TradeShop')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.accomodation"
                :label="$t('Accomodation')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.services"
                :label="$t('Services')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.warehouse"
                :label="$t('WareHouse')"
                color="black"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              style="min-width: 432px; max-width: 432px"
              class="second-general-column"
            >
              <v-checkbox
                v-model="form.production"
                :label="$t('Production')"
                color="black"
                hide-details
                style="padding-bottom: 12px; padding-top: 20px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.foodServices"
                :label="$t('FoodServices')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.notDefined"
                :label="$t('NotDefined')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.warehouseProduction"
                :label="$t('WareHouseProduction')"
                color="black"
                hide-details
                style="padding-bottom: 12px"
              ></v-checkbox>
              <v-checkbox
                v-model="form.otherUsePossible"
                :label="$t('OtherUsePossible')"
                color="black"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div class="section">
          <v-row>
            <div class="circle-number">4</div>
            <h5>{{ $t("AdditionalInfo") }}</h5></v-row
          >
          <v-row>
            <v-row class="box-wrapper" style="padding-top: 20px">
              <button
                v-for="sectionType in sectionTypes"
                :id="sectionType.value"
                :key="sectionType.name"
                item-text="name"
                item-value="value"
                @click="
                  form.sectionType = sectionType.value;
                  chooseSection2(sectionType.value);
                "
                v-bind:class="{
                  'big-box2 icon-box': !sectionType.isChosen,
                  'big-box2-white icon-box active-box': sectionType.isChosen,
                }"
                class="big-box2 icon-box"
              >
                <v-row style="align-items: center">
                  <img
                    v-bind:class="{
                      'big-box2-image': !sectionType.isChosen,
                      'big-box2-image-white': sectionType.isChosen,
                    }"
                    class="big-box2-image2"
                    v-bind:src="
                      require('../../assets/images/' +
                        sectionType.name +
                        '.svg')
                    "
                    alt=""
                  />
                  {{ sectionType.name }}
                </v-row>
              </button>
            </v-row>
            <v-row
              style="margin-top: 0px"
              v-if="
                form.sectionType != 'sanitary' &&
                form.sectionType != 'surroundings' &&
                form.sectionType != 'communication' &&
                form.sectionType != 'heating' &&
                form.sectionType != 'kitchen'
              "
            >
              <v-col cols="6" class="additional-first-row">
                <v-checkbox
                  id="balcony-exists"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.balcony"
                  :label="$t('Balcony')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="balcony-is-closed"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.closedBalcony"
                  :label="$t('ClosedBalcony')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="balcony-is-fully-glazed"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.fullyGlazedBalcony"
                  :label="$t('FullyGlazedBalcony')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="balcony-is-french"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.frenchBalcony"
                  :label="$t('FrenchBalcony')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>

                <v-row style="margin-top: 0" />

                <v-row
                  style="margin-bottom: 12px"
                  v-if="
                    form.estateType !== 'garage' && form.estateType !== 'land'
                  "
                >
                  <BaseInput
                    id="balcony-size"
                    v-model="form.balconySize"
                    type="number"
                    :placeholder="$t('Balcony')"
                    icon-right="SquareMeter.svg"
                  />
                </v-row>

                <v-row
                  v-if="
                    form.estateType !== 'garage' &&
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial'
                  "
                >
                  <BaseInput
                    id="kitchen-size"
                    v-model="form.kitchenSize"
                    :placeholder="$t('Kitchen')"
                    icon-right="SquareMeter.svg"
                    type="number"
                    style="margin-bottom: 12px"
                    @change="calculateKitchenSize"
                  />
                </v-row>

                <v-select
                  clearable
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.parkingOption"
                  :items="parkingOptions"
                  outlined
                  item-text="name"
                  item-value="value"
                  :label="$t('ParkingOption')"
                  style="margin-bottom: -18px; padding-top: 0px"
                ></v-select>

                <v-checkbox
                  id="has-garage"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.garage"
                  :label="$t('Garage')"
                  color="black"
                  hide-details
                  style="padding-top: 8px"
                ></v-checkbox>
                <v-checkbox
                  id="garage-is-underground"
                  v-if="form.estateType === 'apartment'"
                  v-model="form.undergroundGarage"
                  :label="$t('UndergroundGarage')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>

                <v-row style="margin-top: 0" />

                <v-row v-if="form.estateType === 'commercial'">
                  <BaseInput
                    id="kitchen-size"
                    v-model="form.kitchenSize"
                    type="number"
                    :placeholder="$t('Kitchen')"
                    icon-right="SquareMeter.svg"
                  />
                </v-row>

                <v-row
                  style="margin-bottom: 28px"
                  v-if="form.estateType === 'commercial'"
                >
                  <BaseInput
                    id="number-of-desks"
                    v-model="form.numberOfDesks"
                    :placeholder="$t('NumberOfDesks')"
                    icon-right="SquareMeter.svg"
                  />
                </v-row>

                <v-select
                  clearable
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'land' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'house' &&
                    form.estateType != 'house_part' &&
                    form.estateType != 'cottage'
                  "
                  v-model="form.roofType"
                  outlined
                  :items="roofTypes"
                  item-text="name"
                  item-value="value"
                  :label="$t('RoofType')"
                  style="margin-top: 8px !important; width: 432px"
                ></v-select>
                <v-select
                  clearable
                  v-if="
                    form.estateType == 'apartment' ||
                    form.estateType == 'house' ||
                    form.estateType == 'house_part' ||
                    form.estateType == 'cottage'
                  "
                  v-model="form.roofType"
                  outlined
                  item-text="name"
                  item-value="value"
                  :items="roofTypes"
                  :label="$t('RoofType')"
                  style="margin-top: 24px !important; width: 432px"
                ></v-select>
                <v-checkbox
                  id="has-elevator"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.lift"
                  :label="$t('Lift')"
                  color="black"
                  hide-details
                  style="padding-top: 0px; margin-top: -4px !important"
                ></v-checkbox>
                <v-checkbox
                  id="has-parquet"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.parquet"
                  :label="$t('Parquet')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-packet-windows"
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'land' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.packetWindows"
                  :label="$t('PacketWindows')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-furniture"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.furniture"
                  :label="$t('Furniture')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  id="is-furnishing-possible"
                  v-model="form.furnitureAbility"
                  :label="$t('FurnitureChangePossibility')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="plan-through-multiple-floors"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.severalFloorsPlanning"
                  :label="$t('PlanThroughMultipleFloors')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-high-ceilings"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.highCeilings"
                  :label="$t('HighCeilings')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-new-wiring"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.electricalWiring"
                  :label="$t('NewWiring')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-electricity"
                  v-if="form.estateType != 'apartment'"
                  v-model="form.electricity"
                  :label="$t('Electricity')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-wardrobe"
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'land' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.wardrobe"
                  :label="$t('Wardrobe')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="additional-second-row">
                <v-checkbox
                  id="has-attic"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.attic"
                  :label="$t('Attic')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-storeroom"
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'land' &&
                    form.estateType != 'house' &&
                    form.estateType != 'house_part' &&
                    form.estateType != 'cottage'
                  "
                  v-model="form.storeRoom"
                  :label="$t('Storeroom')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-storeroom"
                  v-if="
                    form.estateType == 'house' ||
                    form.estateType == 'house_part' ||
                    form.estateType == 'cottage'
                  "
                  v-model="form.storeRoom"
                  :label="$t('Storeroom')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-terrace"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.terrace"
                  :label="$t('Terrace')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-showcase-windows"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.showCaseWindows"
                  :label="$t('ShowCaseWindows')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-street-entrance"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.streetEntrance"
                  :label="$t('StreetEntrance')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-trestle"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.trestle"
                  :label="$t('Trestle')"
                  color="black"
                  class="trestle"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-goods-lift"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.goodsLift"
                  :label="$t('GoodsLift')"
                  color="black"
                  class="goodslift"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-tv"
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'land' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.tv"
                  :label="'TV'"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-basement"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.basementFloor"
                  :label="$t('BasementFloor')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="is-rooftop-apartment"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.rooftopApartment"
                  :label="$t('RoofTopApartment')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-separate-entrance"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.separateEntrance"
                  :label="$t('SeparateEntrance')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-closed-yard"
                  v-if="
                    form.estateType != 'garage' && form.estateType != 'land'
                  "
                  v-model="form.closedYard"
                  :label="$t('ClosedYard')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-meeting-room"
                  v-if="
                    form.estateType == 'apartment' ||
                    form.estateType == 'commercial'
                  "
                  v-model="form.meetingRoom"
                  :label="$t('MeetingRoom')"
                  color="black"
                  class="meetingRoom"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-industrial-power-supply"
                  v-if="form.estateType != 'land'"
                  v-model="form.industrialPowerSupply"
                  :label="$t('IndustrialPowerSupply')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-climate-device"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.climaticDevice"
                  :label="$t('ClimaticDevice')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-ventilation"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.ventilation"
                  :label="$t('Ventilation')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-well"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.well"
                  :label="$t('Well')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-sewerage"
                  v-if="form.estateType == 'land'"
                  v-model="form.sewerage"
                  :label="$t('Sewerage')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="gas-included"
                  v-if="form.estateType == 'land'"
                  v-model="form.gasIncluded"
                  :label="$t('GasIncluded')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-water"
                  v-if="
                    form.estateType == 'land' || form.estateType == 'commercial'
                  "
                  v-model="form.water"
                  :label="$t('Water')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-road"
                  v-if="form.estateType == 'land'"
                  v-model="form.road"
                  :label="$t('Road')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-public-transport"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.publicTransport"
                  :label="$t('PublicTransport')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              style="margin-top: 0px"
              v-if="form.sectionType == 'sanitary'"
            >
              <v-col cols="6" class="additional-first-row">
                <v-checkbox
                  id="has-washing-machine"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.washingMachine"
                  :label="$t('WashingMachine')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-pool"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.pool"
                  :label="$t('Pool')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-pool"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.pool"
                  :label="$t('Pool')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-boiler"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.boiler"
                  :label="$t('Boiler')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-shower"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.shower"
                  :label="$t('Shower')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-local-sewerage"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'apartment'
                  "
                  v-model="form.localSewerage"
                  :label="$t('LocalSewerage')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-central-sewerage"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'apartment'
                  "
                  v-model="form.centralSewerage"
                  :label="$t('CentralSewerage')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="additional-second-row">
                <v-checkbox
                  id="has-local-water-supply"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'apartment'
                  "
                  v-model="form.localWaterSupply"
                  :label="$t('LocalWaterSupply')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-central-water-supply"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'apartment'
                  "
                  v-model="form.centralWaterSupply"
                  :label="$t('CentralWaterSupply')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-new-plumbing"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.piping"
                  :label="$t('NewPlumbing')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-bath"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.bath"
                  :label="$t('Bath')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-sauna"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.sauna"
                  :label="$t('Sauna')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-separate-toilet-and-bath"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.wcBath"
                  :label="$t('SeparateToiletAndBath')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              style="margin-top: 0px; gap: 32px"
              v-if="form.sectionType == 'surroundings'"
            >
              <v-col cols="6" style="padding: 32px 0 0 0; max-width: 432px">
                <v-select
                  clearable
                  v-if="form.estateType != 'garage'"
                  v-model="form.reservoir"
                  :items="reservois"
                  item-text="name"
                  item-value="value"
                  outlined
                  style="max-width: 432px; margin-bottom: -12px !important"
                  :label="$t('BodyOfWater')"
                ></v-select>
                <v-row v-if="form.estateType !== 'garage'">
                  <BaseInput
                    id="reservoir-name"
                    v-model="form.reservoirName"
                    :placeholder="$t('BodyOfWaterName')"
                    style="margin-bottom: 10px"
                  />
                </v-row>
                <v-row
                  style="margin-top: 0px"
                  v-if="form.estateType !== 'garage'"
                >
                  <BaseInput
                    id="reservoir-distance"
                    v-model="form.reservoirDistance"
                    type="number"
                    style="margin-top: 0px"
                    :placeholder="$t('BodyOfWaterDistance') + ' (m)'"
                  />
                </v-row>
                <v-row
                  style="margin-top: 12px; margin-bottom: 12px"
                  v-if="
                    form.estateType !== 'garage' &&
                    form.estateType !== 'apartment'
                  "
                >
                  <BaseInput
                    id="coastLine-length"
                    v-model="form.coastLineLength"
                    type="number"
                    :placeholder="$t('CoastLineLength')"
                  />
                </v-row>
                <v-select
                  clearable
                  v-if="
                    form.estateType != 'garage' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.neighbours"
                  :items="neighbours"
                  outlined
                  item-text="name"
                  item-value="value"
                  :label="$t('Neighbours')"
                  style="margin-top: 12px !important; max-width: 432px"
                ></v-select>
              </v-col>
              <v-col cols="6" style="padding: 32px 0 0 0; max-width: 432px">
                <v-select
                  clearable
                  v-if="form.estateType != 'garage'"
                  v-model="form.building"
                  :items="buildings"
                  item-text="name"
                  item-value="value"
                  outlined
                  style="margin-bottom: -12px"
                  :label="$t('Building1')"
                ></v-select>

                <v-select
                  clearable
                  v-if="form.estateType != 'garage'"
                  v-model="form.roads"
                  :items="roads"
                  outlined
                  item-text="name"
                  item-value="value"
                  style="margin-bottom: -12px"
                  :label="$t('Roads')"
                ></v-select>
                <v-select
                  clearable
                  v-if="form.estateType != 'garage'"
                  v-model="form.location"
                  :items="locations"
                  item-text="name"
                  item-value="value"
                  outlined
                  :label="$t('Location')"
                ></v-select>

                <v-checkbox
                  id="is-forest-near"
                  v-if="
                    form.estateType != 'commercial' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'garage'
                  "
                  style="margin-top: -8px !important"
                  v-model="form.forestIsNear"
                  :label="$t('ForestIsNear')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              style="margin-top: 0px"
              v-if="form.sectionType == 'communication'"
            >
              <v-col
                cols="6"
                style="
                  padding-top: 32px;
                  padding-bottom: 12px;
                  padding-left: 0px;
                "
              >
                <v-select
                  clearable
                  v-if="form.estateType != 'land'"
                  v-model="form.securitySystem"
                  :items="securitySystems"
                  item-text="name"
                  item-value="value"
                  outlined
                  :label="$t('SelectSecuritySystem')"
                ></v-select>

                <v-checkbox
                  id="has-internet"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.internet"
                  :label="$t('Internet')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-phone"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'commercial' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.telephone"
                  :label="$t('Phone')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-cable-tv"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'commercial' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.cableTv"
                  :label="$t('CableTv')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-security-door"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.securityDoor"
                  :label="$t('SecurityDoor')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                style="
                  padding-top: 44px;
                  padding-bottom: 0px;
                  padding-left: 20px;
                  padding-right: 0px;
                "
              >
                <v-checkbox
                  id="is-staircase-locked"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.staircaseLocked"
                  :label="$t('LockedStairway')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-neighbourhood-watch"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.neighbourhoodSecurity"
                  :label="$t('NeighbourhoodWatch')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-guard"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.guard"
                  :label="$t('Guard')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-video-surveillance"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.videoSecurity"
                  :label="$t('VideoSurveillance')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-video-surveillance"
                  v-if="form.estateType == 'garage'"
                  v-model="form.videoSecurity"
                  :label="$t('VideoSurveillance')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-fence"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'commercial'
                  "
                  v-model="form.fence"
                  :label="$t('Fence')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row style="margin-top: 0px" v-if="form.sectionType == 'heating'">
              <v-col
                cols="6"
                class="additional-first-row"
                style="padding-bottom: 12px"
              >
                <v-checkbox
                  id="has-fireplace"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.firePlace"
                  :label="$t('Fireplace')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-central-gas"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'apartment'
                  "
                  v-model="form.centralGas"
                  :label="$t('CentralGas')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-central-heating"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.centralHeating"
                  :label="$t('CentralHeating')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-central-heating"
                  v-if="form.estateType == 'garage'"
                  v-model="form.centralHeating"
                  :label="$t('CentralHeating')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-gas-heating"
                  v-if="form.estateType != 'land'"
                  v-model="form.gasHeating"
                  :label="$t('GasHeating')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-furnace-heating"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.furnaceHeating"
                  :label="$t('FurnaceHeating')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-electrical-heating"
                  v-if="form.estateType != 'land'"
                  v-model="form.electricalHeating"
                  :label="$t('ElectricalHeating')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-solid-fuel"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.solidFuel"
                  :label="$t('SolidFuel')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="additional-second-row">
                <v-checkbox
                  id="has-combined-heating"
                  v-if="form.estateType != 'land'"
                  v-model="form.combinedHeating"
                  :label="$t('CombinedHeating')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-geothermal-heating"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.geothermalHeating"
                  :label="$t('GeothermalHeating')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-air-conditioner"
                  v-if="form.estateType != 'land'"
                  v-model="form.conditioner"
                  :label="$t('AirConditioner')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-floor-heating"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'commercial'
                  "
                  v-model="form.floorHeating"
                  :label="$t('FloorHeating')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-air-heat-pump"
                  v-if="form.estateType != 'land'"
                  v-model="form.airHeatPump"
                  :label="$t('AirHeatPump')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-air-water-pump"
                  v-if="form.estateType != 'land'"
                  v-model="form.airWaterPump"
                  :label="$t('AirWaterHeatPump')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-liquid-fuel"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'apartment' &&
                    form.estateType != 'commercial' &&
                    form.estateType != 'garage'
                  "
                  v-model="form.liquidFuel"
                  :label="$t('LiquidFuel')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row style="margin-top: 0px" v-if="form.sectionType == 'kitchen'">
              <v-col
                cols="6"
                class="additional-first-row"
                style="padding-bottom: 12px !important"
              >
                <!--  <BaseDropdown
                    id="stove"
                    :placeholder="$t('Stove')"
                    v-model="form.stove"
                    :show-dropdown-arrow="true"
                    :items="stoves"
                    style="width: 432px"
                  /> -->
                <v-checkbox
                  id="has-electrical-stove"
                  v-if="
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial' &&
                    form.estateType !== 'garage'
                  "
                  v-model="form.electricalStove"
                  :label="$t('ElectricalStove')"
                  color="black"
                  hide-details
                  style="padding-top: 0px; margin-top: -2px !important"
                ></v-checkbox>
                <v-checkbox
                  id="has-gas-stove"
                  v-if="
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial' &&
                    form.estateType !== 'garage'
                  "
                  v-model="form.gasStove"
                  :label="$t('GasStove')"
                  color="black"
                  hide-details
                  style="padding-top: 26px; margin-top: -2px !important"
                ></v-checkbox>
                <v-checkbox
                  id="has-ceramic-stove"
                  v-if="
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial' &&
                    form.estateType !== 'garage'
                  "
                  v-model="form.ceramicStove"
                  :label="$t('CeramicStove')"
                  color="black"
                  hide-details
                  style="padding-top: 26px; margin-top: -2px !important"
                ></v-checkbox>
                <v-checkbox
                  id="has-open-kitchen"
                  v-if="form.estateType == 'apartment'"
                  v-model="form.openedKitchen"
                  :label="$t('OpenKitchen')"
                  color="black"
                  hide-details
                  style="padding-top: 26px; margin-top: -2px !important"
                ></v-checkbox>
                <v-checkbox
                  id="has-kitchen"
                  v-if="form.estateType == 'commercial'"
                  v-model="form.kitchen"
                  :label="$t('Kitchen')"
                  color="black"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  id="has-fridge"
                  v-if="
                    form.estateType != 'land' &&
                    form.estateType != 'garage' &&
                    form.estateType != 'house' &&
                    form.estateType != 'house_part' &&
                    form.estateType != 'cottage'
                  "
                  v-model="form.fridge"
                  :label="$t('Fridge')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-fridge"
                  v-if="
                    form.estateType == 'house' ||
                    form.estateType == 'house_part' ||
                    form.estateType == 'cottage'
                  "
                  v-model="form.fridge"
                  :label="$t('Fridge')"
                  color="black"
                  hide-details
                  style="padding-top: 26px"
                ></v-checkbox>
                <v-checkbox
                  id="has-kitchen-furniture"
                  v-if="
                    form.estateType != 'land' && form.estateType != 'garage'
                  "
                  v-model="form.kitchenFurniture"
                  :label="$t('KitchenFurniture')"
                  color="black"
                  style="padding-top: 26px"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="additional-second-row">
                <v-checkbox
                  id="has-firewood-stove"
                  v-if="
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial' &&
                    form.estateType !== 'garage'
                  "
                  v-model="form.fireWoodStove"
                  :label="$t('FireWoodStove')"
                  color="black"
                  hide-details
                  style="padding-top: 0px"
                ></v-checkbox>
                <v-checkbox
                  id="has-induction-stove"
                  v-if="
                    form.estateType !== 'land' &&
                    form.estateType !== 'commercial' &&
                    form.estateType !== 'garage'
                  "
                  v-model="form.inductionStove"
                  :label="$t('InductionHob')"
                  color="black"
                  hide-details
                  style="padding-top: 22px"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-row>
        </div>
        <div class="section">
          <v-row>
            <div class="circle-number">5</div>
            <h5>{{ $t("LanguageSpecificInfo") }}</h5>
          </v-row>
          <v-row class="box-wrapper" style="padding-top: 20px">
            <button
              v-for="languageType in languageTypes"
              :key="languageType.name"
              item-text="name"
              item-value="value"
              @click="
                chooseBox3(languageType.value);
              "
              v-bind:class="{
                'big-box1': !languageType.isChosen,
                'big-box1-white active-box': languageType.isChosen,
              }"
            >
              <v-row style="align-items: center">
                {{ languageType.name }}
              </v-row>
            </button>
          </v-row>
          <v-row
            style="margin-top: 0px"
            v-if="form.languageType === 'estonian'"
          >
            <v-row style="margin-top: 32px">
              <h6>{{ $t("Slogan") }}</h6>
            </v-row>
            <v-row style="margin-top: 16px; width: 100%">
              <BaseInput
                id="slogan"
                v-model="form.slogan"
                :max-chars-length="'64'"
                style="max-width: unset"
              />
            </v-row>
            <v-row class="subheading">
              <h6>{{ $t("ObjectDescription") }}</h6>
            </v-row>
            <v-row style="padding-top: 4px; width: 100%">
              <textarea
                @input="replaceSymbols('description')"
                v-model="form.description"
                id="textarea"
                name="textarea"
              >
              </textarea>
            </v-row>
            <v-row style="gap: 8px">
              <BaseInput
                id="additional-info-link"
                v-model="form.additionalInfoLink"
                :placeholder="$t('AdditionalInfoLink')"
                style="width: 444px; max-width: unset"
              />
              <BaseInput
                id="link-title"
                v-model="form.linkTitle"
                :placeholder="$t('LinkTitle')"
                style="width: 444px; max-width: unset"
              />
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="margin-top: 0px; padding-top: 32px !important"
            >
              <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="padding-top: 4px; width: 100%"
            >
              <BaseInput
                id="services-in-neighbourhood"
                v-model="form.servicesInNeighbourhood"
                style="max-width: unset"
              />
            </v-row>
          </v-row>

          <v-row style="margin-top: 0px" v-if="form.languageType == 'english'">
            <v-row style="margin-top: 32px">
              <h6>{{ $t("Slogan") }}</h6>
            </v-row>
            <v-row style="margin-top: 16px; width: 100%">
              <BaseInput
                id="additional-info-link"
                v-model="form.sloganENG"
                :max-chars-length="'64'"
                style="max-width: unset"
              />
            </v-row>
            <v-row class="subheading">
              <h6>{{ $t("ObjectDescription") }}</h6>
            </v-row>
            <v-row style="padding-top: 4px; width: 100%">
              <textarea
                @input="replaceSymbols('descriptionENG')"
                v-model="form.descriptionENG"
                id="textarea"
                name="textarea"
              >
              </textarea>
            </v-row>
            <v-row style="gap: 8px">
              <BaseInput
                id="additional-info-link-eng"
                v-model="form.additionalInfoLinkENG"
                :placeholder="$t('AdditionalInfoLink')"
                style="width: 444px; max-width: unset"
              />
              <BaseInput
                id="link-title-eng"
                v-model="form.linkTitleENG"
                :placeholder="$t('LinkTitle')"
                style="width: 444px; max-width: unset"
              />
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="margin-top: 0px; padding-top: 32px !important"
            >
              <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="padding-top: 4px; width: 100%"
            >
              <BaseInput
                id="services-in-neighbourhood-eng"
                v-model="form.servicesInNeighbourhoodENG"
                style="max-width: unset"
              />
            </v-row>
          </v-row>
          <v-row style="margin-top: 0px" v-if="form.languageType == 'russian'">
            <v-row style="margin-top: 32px">
              <h6>{{ $t("Slogan") }}</h6>
            </v-row>
            <v-row style="margin-top: 16px; width: 100%">
              <BaseInput
                id="additional-info-ru"
                v-model="form.sloganRU"
                :max-chars-length="'64'"
                style="max-width: unset"
              />
            </v-row>
            <v-row class="subheading">
              <h6>{{ $t("ObjectDescription") }}</h6>
            </v-row>
            <v-row style="padding-top: 4px; width: 100%">
              <textarea
                @input="replaceSymbols('descriptionRU')"
                v-model="form.descriptionRU"
                id="textarea"
                name="textarea"
              >
              </textarea>
              <!--              <BaseTextarea-->
              <!--                  id="additional-info-ru"-->
              <!--                  v-model="form.descriptionRU"-->
              <!--                  style="max-width: unset"-->
              <!--              />-->
            </v-row>
            <v-row style="gap: 8px">
              <BaseInput
                id="additional-info-link-ru"
                v-model="form.additionalInfoLinkRU"
                :placeholder="$t('AdditionalInfoLink')"
                style="width: 444px; max-width: unset"
              />
              <BaseInput
                id="link-title-ru"
                v-model="form.linkTitleRU"
                :placeholder="$t('LinkTitle')"
                style="width: 444px; max-width: unset"
              />
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="margin-top: 0px; padding-top: 32px !important"
            >
              <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="padding-top: 4px; width: 100%"
            >
              <BaseInput
                id="additional-info-ru"
                v-model="form.servicesInNeighbourhoodRU"
                style="max-width: unset"
              />
            </v-row>
          </v-row>

          <v-row style="margin-top: 0px" v-if="form.languageType == 'finnish'">
            <v-row style="margin-top: 32px">
              <h6>{{ $t("Slogan") }}</h6>
            </v-row>
            <v-row style="margin-top: 16px; width: 100%">
              <BaseInput
                id="additional-info-fi"
                v-model="form.sloganFI"
                :max-chars-length="'64'"
                style="max-width: unset"
              />
            </v-row>
            <v-row class="subheading">
              <h6>{{ $t("ObjectDescription") }}</h6>
            </v-row>
            <v-row style="padding-top: 4px; width: 100%">
              <textarea
                @input="replaceSymbols('descriptionFI')"
                v-model="form.descriptionFI"
                id="textarea"
                name="textarea"
              >
              </textarea>
            </v-row>
            <v-row style="gap: 8px">
              <BaseInput
                id="additional-info-link-ru"
                v-model="form.additionalInfoLinkFI"
                :placeholder="$t('AdditionalInfoLink')"
                style="width: 444px; max-width: unset"
              />
              <BaseInput
                id="link-title-ru"
                v-model="form.linkTitleFI"
                :placeholder="$t('LinkTitle')"
                style="width: 444px; max-width: unset"
              />
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="margin-top: 0px; padding-top: 32px !important"
            >
              <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
            </v-row>
            <v-row
              v-if="
                form.estateType != 'land' &&
                form.estateType != 'garage' &&
                form.estateType != 'commercial'
              "
              style="padding-top: 4px; width: 100%"
            >
              <BaseInput
                id="additional-info-fi"
                v-model="form.servicesInNeighbourhoodFI"
                style="max-width: unset"
              />
            </v-row>
          </v-row>
        </div>
        <div class="section">
          <v-row>
            <div class="circle-number">6</div>
            <h5>{{ $t("Media") }}</h5></v-row
          >
          <v-row class="subheading">
            <h6>{{ $t("Pictures") }}</h6></v-row
          >
          <v-row style="padding-top: 4px; width: 100%">
            <UploadImages
              v-if="!edit"
              :Imgs="[]"
              :is-visible="true"
              @changed="putImages($event)"
              class="image-uploader"
              :clearAll="$t('ClearAll')"
            />
            <template v-if="edit">
              <UploadImages
                v-if="form.meta?.images?.status?.original !== 'uploading' && form.meta?.images?.status?.original !== 'pending'"
                :Imgs2="form.images"
                @changed="putImages($event)"
                :is-visible="true"
                class="image-uploader"
                :clearAll="$t('ClearAll')"
              />
              <div v-else>
                <div v-if="form.meta?.images?.status?.original === 'uploading' || form.meta?.images?.status?.original === 'pending'" class="listing-images-updating">
                  <img height="20" width="20" :src="require('@/assets/images/alert.svg')" alt="info" />
                  <p v-if="form.meta?.images?.count - (form.images?.length || 0) > 0">
                    {{ form.meta?.images?.count - (form.images?.length || 0) }} {{$tc('ImagesAreCurrentlyBeingUploaded', form.meta?.images?.count - (form.images?.length || 0))}}
                  </p>
                  <p v-else>{{$t('ImagesAreBeingUpdated')}}</p>
                </div>
              </div>
            </template>
          </v-row>
          <v-row
            style="
              width: 100%;
              height: 52px;
              display: flex;
              flex-wrap: nowrap;
              gap: 16px;
              align-items: center;
              white-space: nowrap;
            "
          >
            <BaseToggle
              v-model="form.isWatermarkApplied"
              @click="changeWatermarkStatus($event)"
              :label="$t('ApplyWatermarkToImages')"
            />
            <div
              v-if="form.isWatermarkApplied && companyWatermarkSets.length > 0"
              style="width: 284px"
            >
              <BaseDropdown
                :items="companyWatermarkSets"
                v-model="form.appliedWatermarkSetId"
                :dropdown-offset-y="60"
                :show-dropdown-arrow="true"
                :dropdown-allow-null="false"
                :placeholder="$t('Watermark')"
              />
            </div>
          </v-row>
          <v-row style="width: 100%">
            <BaseInput
              id="video-link"
              v-model="form.videoLink"
              :placeholder="$t('VideoLink')"
              style="max-width: unset; width: 100%"
            />
          </v-row>
          <v-row style="width: 100%">
            <BaseInput
              id="Virtual-tour-link"
              v-model="form.virtualTourLink"
              :placeholder="$t('VirtualTourLink')"
              style="max-width: unset; width: 100%"
            />
          </v-row>
        </div>
        <div
          v-bind:class="{
            'buttons-row justify-end': !edit,
            'buttons-row justify-between': edit,
          }"
        >
          <div v-if="edit">
            <button
              id="listing-delete"
              v-if="this.form.statusCode === 1"
              @click="openDeleteModal"
              class="delete-button"
              color="blue darken-1"
              text
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/TrashBin.svg"
                alt=""
              />
              {{ $t("Delete") }}
            </button>
            <button
              @click="openDeletePermanentlyModal"
              v-if="this.form.status === 'archived'"
              class="delete-button"
              color="blue darken-1"
              text
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/TrashBin.svg"
                alt=""
              />
              {{ $t("Delete") }}
            </button>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 12px;
            "
          >
            <button
              @click="$router.back()"
              class="cancel padding-top"
              style="padding-left: 12px; padding-right: 12px"
            >
              {{ $t("Cancel") }}
            </button>
            <div
              class="cancel"
              @click="saveListing(form, false)"
              style="margin-right: 12px; cursor: pointer"
            >
              <div
                style="position: relative; width: 30px; height: 30px"
                v-if="isLoading"
                class="loader"
              ></div>
              <p v-if="!isLoading">{{ $t("Save") }}</p>
            </div>
            <div
              id="save-button"
              class="add-button"
              @click="saveListing(form, true)"
            >
              <div
                style="position: relative; width: 30px; height: 30px"
                v-if="isLoading"
                class="loader"
              ></div>
              <img
                v-if="!isLoading"
                style="margin-right: 9px"
                alt=""
                src="../../assets/images/checkmarkCircled.svg"
              />
              <p v-if="!isLoading">{{ $t("SaveAndExit") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      style="z-index: 121"
      :removing="false"
      @canceled="deleteModalOpen = false"
      @approved="deleteListing()"
      v-if="deleteModalOpen"
    />
    <DeleteModal
      style="z-index: 121"
      :removing="false"
      @canceled="deletePermanentlyModalOpen = false"
      @approved="deleteListingPermanently()"
      v-if="deletePermanentlyModalOpen"
    />
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeListingStatus"
      @open-booking-modal="openBookingDetailsModal"
      v-if="statusesOpen"
      :position="position"
      :listing="form"
      :multi="false"
    />
    <BookingDetailsModal
      v-if="bookingDetailsModalOpen"
      :opened-listing="bookedListing"
      @update-listing="changeListingStatus"
      @close-modal="bookingDetailsModalOpen = false"
    >
    </BookingDetailsModal>
    <LinkListingModal
      v-if="isLinkListing"
      :item.sync="form"
      :linked-entity="deal"
      @close-modal="isLinkListing = false"
      @new-link="handleNewLink"
      :key="updater"
    />
    <ConfirmationModal
      v-if="linkConfirmation"
      @onConfirm="handleLinkConfirm(selectedItem)"
      @onCancel="() => (linkConfirmation = false)"
    >
      <div style="display: flex; flex-direction: column; width: 100%;">
        <div class="text">{{ selectedItem.module == 'development' ?  $t("LinkWithDevelopmentProject") : $t("LinkWithProject") }}</div>
        <h5 class="title-text" style="display: flex; width: 100%; padding-top: 10px;">
          {{ selectedItem.name }}
        </h5>
      </div>
    </ConfirmationModal>
  </div>
</template>

<script>
import axios from "axios";
import UploadImages from "../../components/common/vue-upload-drop-images/dist/vue-upload-drop-images";
import Autocomplete from "../../components/common/Autocomplete";
import Sidepanel from "@/components/Deals/SidepanelAdd";
import ClickOutside from "vue-click-outside";
import ObjectSelection from "@/components/Listings/ObjectSelection";
import AddressFinder from "@/components/Deals/AddressFinder";
import nanoid from "nanoid";
import { mapActions, mapGetters } from "vuex";
import Dropdown from "@/components/common/Dropdown";
import DeleteModal from "@/components/common/DeleteModal.vue";
import BaseInput from "@/components/common/BaseInput";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import listingApi from "@/http/listing";
import DatePick from "vue-date-pick";
import BookingDetailsModal from "../../components/Listings/BookingDetailsModal";
import BaseToggle from "../../components/common/BaseToggle";
import BaseDropdown from "@/components/common/BaseDropdown";
import LinkListingModal from "@/components/Listings/LinkListingModal"
import ConfirmationModal from "@/components/common/ConfirmationModal";
import AddEditOutputs from "@/components/Listings/AddEditOutputs";

//import moment from "moment";
const locationsEstonia = require("../../components/common/locations_estonia2");
const locationsLatvia = require("../../components/common/locations_latvia");

export default {
  name: "ListingsAdd",
  components: {
    AddEditOutputs,
    BaseToggle,
    BookingDetailsModal,
    UploadImages,
    Autocomplete,
    Sidepanel,
    ObjectSelection,
    AddressFinder,
    Dropdown,
    DeleteModal,
    //Checkbox,
    BaseInput,
    BaseDropdown,
    //BaseTextarea,
    StatusDropdown,
    DatePick,
    LinkListingModal,
    ConfirmationModal
  },
  directives: {
    ClickOutside,
  },
  props: ["project"],
  watch: {
    listingsUpdater: function () {
      this.getListing()
    },
    selectedCounty(newCounty) {
      if (newCounty) {
        this.county = newCounty;
      }
    },
    cities(newCities) {
      if (this.tempCityValue) {
        const cityObject = newCities.find(c => c.value === this.tempCityValue || c.name === this.tempCityValue);
        if (cityObject) {
          this.selectedCity = cityObject;
        }
        this.tempCityValue = null;
      }
    },
    selectedCity(newCity) {
      if (newCity) {
        this.city = newCity;
      }
    },
    districts(newDistricts) {
      if (this.tempDistrictValue) {
        const districtObject = newDistricts.find(d => d.value === this.tempDistrictValue || d.name === this.tempDistrictValue);
        if (districtObject) {
          this.selectedDistrict = districtObject;
        }
        this.tempDistrictValue = null;
      }
    },
    "form.languageType": function (newVal) {
      if (newVal === undefined) {
        this.chooseBox3('estonian')
      }
    },
    landAreaUnit(value) {
      // if form.landArea.ha or m2 exists then convert values here
      const reg = new RegExp("\\d*\\.?\\d*");
      if (this.form.landArea.m2 && !this.form.landArea.ha && value === "ha") {
        if (
          reg.test(this.form.landArea.m2) &&
          this.isValidNumber(this.form.landArea.m2)
        ) {
          this.form.landArea.ha = (this.form.landArea.m2 * 0.0001).toFixed(2);
        }
      } else if (
        this.form.landArea.ha &&
        !this.form.landArea.m2 &&
        value === "m2"
      ) {
        if (
          reg.test(this.form.landArea.ha) &&
          this.isValidNumber(this.form.landArea.ha)
        ) {
          this.form.landArea.m2 = (this.form.landArea.ha * 10000).toFixed(2);
        }
      }
      //
      this.activePriceCalculatingInput = value;
      this.priceCalculate();
    },
    rightEstateType: function () {
      this.updateHTML++;
    },
    object() {
      if (this.object && this.object.address) {
        this.aadressSearch.setAddress(this.object.address);
        this.activatePlaceholder(true);
      }
    },
    objectSelectionOpen() {},
    activeView: function (val) {
      this.$emit("viewChanged", val);
    },

    dealsLoaded: function () {
      this.closeMenu();
    },
    "form.addressType": function (newValue) {
      if (newValue === "generic") {
        this.form.marketplaces.forEach((marketplace) => {
          if (
            marketplace.name === "kv" ||
            marketplace.name === "kv24" ||
            marketplace.name === "city24"
          ) {
            marketplace.isActive = false;
          }
        });
      }
    },
    "form.availableImmediately": function () {
      if (this.form.availableImmediately) {
        this.form.dateAvailable = null;
      }
    },

    form: {
      handler() {
        if (this.form.linkedDeals.length > 0) {
          this.isErrorDeal = false;
        }
        if (this.inAddress !== null) {
          this.isErrorAddress = false;
        }

        if (this.form.houseNumber !== null) {
          this.isErrorHouseNr = false;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      lastReplacement: null,
      companyWatermarkSets: [],
      bookingDetailsModalOpen: false,
      bookingUpdater: 0,
      bookedListing: null,
      valueChanged: 0,
      activePriceCalculatingInput: "",
      showErrors: null,
      errors: false,
      sloganLengthError: false,
      rightEstateType: null,
      updateHTML: 0,
      addressSelected: false,
      landAreaUnit: "m2",
      isShortTermRent: false,
      deleteModalOpen: false,
      deletePermanentlyModalOpen: false,
      addToProject: false,
      edit: false,
      deal: null,
      selectedCounty: null,
      selectedCity: null,
      selectedDistrict: null,
      county: null,
      city: null,
      cityPart: null,
      countiesDisabled: true,
      citiesDisabled: true,
      districtsDisabled: true,
      countiesUpdater: 0,
      citiesUpdater: 0,
      districtsUpdater: 0,
      projectAddOpen: false,
      objectSelectionOpen: false,
      position: {},
      activeMarketplaces: {},
      closeDealMenu: 0,
      dealsLoaded: false,
      squareMeterPriceKey: 0,
      priceKey: 0,
      chosenLinkedDeal: null,
      chosenLinkedDealName: "",
      chosenLinkedDevelopmentName: "",
      mapCoordinates: { lat: 59.437109, lng: 24.743749 },
      markerCoordinates: { lat: 59.437109, lng: 24.743749 },
      dealTypes: [
        { name: this.$t("Sale"), isChosen: true, value: "sale" },
        { name: this.$t("Rent"), isChosen: false, value: "rent" },
        {
          name: this.$t("ShortTermRent"),
          isChosen: false,
          value: "short_term_rent",
        },
      ],
      estateTypes: [
        { name: this.$t("Apartment"), isChosen: true, value: "apartment" },
        { name: this.$t("House"), isChosen: false, value: "house" },
        { name: this.$t("Land"), isChosen: false, value: "land" },
        {
          name: this.$t("Commercial"),
          isChosen: false,
          value: "commercial",
        },
        { name: this.$t("Maisonette"), isChosen: false, value: "house_part" },
        { name: this.$t("Cottage"), isChosen: false, value: "cottage" },
        { name: this.$t("Garage"), isChosen: false, value: "garage" },
      ],
      sectionTypes: [
        { name: this.$t("Extras"), isChosen: true, value: "extras" },
        {
          name: this.$t("SanitaryArrangements"),
          isChosen: false,
          value: "sanitary",
        },
        {
          name: this.$t("Surroundings"),
          isChosen: false,
          value: "surroundings",
        },
        {
          name: this.$t("Communications&Security"),
          isChosen: false,
          value: "communication",
        },
        {
          name: this.$t("HeatingandVentilation"),
          isChosen: false,
          value: "heating",
        },
        { name: this.$t("Kitchen"), isChosen: false, value: "kitchen" },
      ],
      languageTypes: [
        {
          name: this.$t("EstonianLanguage"),
          isChosen: true,
          value: "estonian",
        },
        { name: this.$t("EnglishLanguage"), isChosen: false, value: "english" },
        { name: this.$t("RussianLanguage"), isChosen: false, value: "russian" },
        { name: this.$t("FinnishLanguage"), isChosen: false, value: "finnish" },
      ],
      counties: [{ name: this.$t("County"), value: "county", _id: nanoid(16) }],
      conditions: [
        { name: this.$t("NewBuilding"), value: "ALACRITY_NEW_BUILDING" },
        {
          name: this.$t("NewlyDecorated"),
          value: "ALACRITY_TYPE_UUSVIIMISTLUS",
        },
        { name: this.$t("Renovated"), value: "ALACRITY_RENEWED" },
        { name: this.$t("EssentialRepairDone"), value: "ALACRITY_SAN" },
        { name: this.$t("Satisfactory"), value: "ALACRITY_KESKMINE" },
        { name: this.$t("NeedsCompleteRenewal"), value: "ALACRITY_TYPE_KAP" },
        { name: this.$t("NeedsDecoration"), value: "ALACRITY_TYPE_REM" },
        { name: this.$t("Ready"), value: "ALACRITY_READY" },
      ],
      buildingTypes: [
        { name: this.$t("House"), value: "BUILDING_TYPE_HOUSE" },
        { name: this.$t("RowHouseBox"), value: "BUILDING_TYPE_HOUSEBOX" },
        { name: this.$t("HouseShare"), value: "BUILDING_TYPE_HOUSESHARE" },
        { name: this.$t("Cottage"), value: "BUILDING_TYPE_COTTAGE" },
        { name: this.$t("FarmHouse"), value: "BUILDING_TYPE_FARMHOUSE" },
        { name: this.$t("Other"), value: "BUILDING_TYPE_OTHER" },
      ],
      buildingMaterials: [
        { name: this.$t("StoneHouse"), value: "BUILDING_MATERIAL_TYPE_KIVI" },
        { name: this.$t("LogHouse"), value: "BUILDING_MATERIAL_TYPE_PALK" },
        { name: this.$t("PanelHouse"), value: "BUILDING_MATERIAL_TYPE_PANEEL" },
        { name: this.$t("WoodenHouse"), value: "BUILDING_MATERIAL_TYPE_PUU" },
      ],
      energyLabels: [
        { name: "A", value: "ENERGY_CLASS_A" },
        { name: "B", value: "ENERGY_CLASS_B" },
        { name: "C", value: "ENERGY_CLASS_C" },
        { name: "D", value: "ENERGY_CLASS_D" },
        { name: "E", value: "ENERGY_CLASS_E" },
        { name: "F", value: "ENERGY_CLASS_F" },
        { name: "G", value: "ENERGY_CLASS_G" },
        { name: "H", value: "ENERGY_CLASS_H" },
        { name: this.$t("NoCertificate"), value: "no_certificate" },
      ],
      parkingOptions: [
        { name: this.$t("ForFee"), value: "PARKING_FEE" },
        { name: this.$t("FreeParking"), value: "PARKING_FREE" },
        { name: this.$t("NoParking"), value: "NO_PARKING" },
      ],
      roofTypes: [
        { name: this.$t("Bitumen"), value: "ROOF_TYPE_BITUMIN" },
        { name: this.$t("Eternit"), value: "ROOF_TYPE_ETERNIIT" },
        { name: this.$t("Stone"), value: "ROOF_TYPE_KIVI" },
        { name: this.$t("Slate"), value: "ROOF_TYPE_SLATE" },
        { name: this.$t("Tiling"), value: "ROOF_TYPE_TILING" },
        { name: this.$t("TinSheet"), value: "ROOF_TYPE_PLEKK" },
        { name: this.$t("PVC"), value: "ROOF_TYPE_PVC" },
        { name: this.$t("RolledMaterial"), value: "ROOF_TYPE_RULL" },
        { name: this.$t("ZincSheet"), value: "ROOF_TYPE_TSINKPLEKK" },
      ],
      detailedPlans: [
        { name: this.$t("Exist"), value: "DETAILED_PLAN_EXIST" },
        { name: this.$t("None"), value: "DETAILED_PLAN_NONE" },
        { name: this.$t("Started"), value: "DETAILED_PLAN_STARTED" },
      ],
      /*  stoves: [
        { name: this.$t("ElectricalStove"), value: "STOVE_ELECTRIC" },
        { name: this.$t("GasStove"), value: "STOVE_GAS" },
        { name: this.$t("CeramicStove"), value: "STOVE_CERAMIC" },
        { name: this.$t("FireWoodStove"), value: "STOVE_WOOD" },
        { name: this.$t("InductionHob"), value: "STOVE_INDUCTION" },
      ], */
      readinesses: [
        { name: this.$t("Ready"), value: "READINESS_READY" },
        {
          name: this.$t("BoxWithDoorsAndWindows"),
          value: "READINESS_BOX_WITH_DOORS",
        },
        { name: this.$t("RoofedBox"), value: "READINESS_ROOFED_BOX" },
        { name: this.$t("RooflessBox"), value: "READINESS_ROOFLESS_BOX" },
        { name: this.$t("Foundation"), value: "READINESS_FOUNDATION" },
      ],
      limitations: [
        { name: this.$t("InMortage"), value: "CONSTRAINTS_MORTGAGE" },
        { name: this.$t("OtherLimitations"), value: "CONSTRAINTS_OTHER" },
        { name: this.$t("Pledge"), value: "CONSTRAINTS_PANT" },
        { name: this.$t("PreservedTerritory"), value: "CONSTRAINTS_PROTECTED" },
        { name: this.$t("RentAgreement"), value: "CONSTRAINTS_RENT" },
        { name: this.$t("UnderSeizure"), value: "CONSTRAINTS_DISTRESS" },
      ],
      securitySystems: [
        { name: this.$t("CablingDone"), value: "SECURITY_SYSTEM_CABLING" },
        { name: this.$t("Installed"), value: "SECURITY_SYSTEM_INSTALLED" },
      ],
      reservois: [
        { name: this.$t("Sea"), value: "WATERBODY_SEA" },
        { name: this.$t("Lake"), value: "WATERBODY_LAKE" },
        { name: this.$t("River"), value: "WATERBODY_RIVER" },
        { name: this.$t("OtherWaterBody"), value: "WATERBODY_OTHER" },
      ],
      neighbours: [
        { name: this.$t("Around"), value: "AROUND" },
        { name: this.$t("NextTo"), value: "NEXT_TO" },
        { name: this.$t("AtOneSide"), value: "AT_ONE_SIDE" },
        { name: this.$t("Farther"), value: "FARTHER" },
      ],
      roads: [
        { name: this.$t("InGoodCondition"), value: "ROADS_GOOD_CONDITION" },
        {
          name: this.$t("InSatisfactoryCondition"),
          value: "ROADS_SATISFACTORY_CONDITION",
        },
        { name: this.$t("InBadCondition"), value: "ROADS_BAD_CONDITION" },
        { name: this.$t("Paved"), value: "ROADS_PAVED" },
        { name: this.$t("GravelRoad"), value: "ROADS_GRAVEL" },
      ],
      locations: [
        { name: this.$t("InCentre"), value: "LOCATION_CENTRE" },
        { name: this.$t("InSuburb"), value: "LOCATION_SUBURB" },
        { name: this.$t("OutsideTheSettlement"), value: "LOCATION_OUTSIDE" },
        { name: this.$t("InTheCity"), value: "LOCATION_CITY" },
      ],
      buildings: [
        { name: this.$t("PrivateHouses"), value: "BUILDING_PRIVATE_HOUSE" },
        {
          name: this.$t("PrivateHousesOrApartments"),
          value: "BUILDING_PRIVATE_HOUSEAPART",
        },
        { name: this.$t("ApartmentBuildings"), value: "BUILDING_APARTMENT" },
        { name: this.$t("CommercialBuildings"), value: "BUILDING_COMMERCIAL" },
        {
          name: this.$t("ManufacturingBuildings"),
          value: "BUILDING_MANUFACTURE",
        },
        { name: this.$t("NoBuildings"), value: "BUILDING_NO" },
      ],
      ownershipForms: [
        { name: this.$t("ApartmentOwnership"), value: "PROPERTY_KORTERIOMAND" },
        { name: this.$t("CommonOwnership"), value: "PROPERTY_TYPES" },
        { name: this.$t("CooperativeHousing"), value: "PROPERTY_ELAMUYHISTU" },
        { name: this.$t("ImmovableProperty"), value: "PROPERTY_KINNISTU" },
        { name: this.$t("MovableProperty"), value: "PROPERTY_VALLASASI" },
        { name: this.$t("RightOfSuperficies"), value: "PROPERTY_BUILDING" },
        { name: this.$t("Municipal"), value: "PROPERTY_MUNICIPAL" },
      ],
      image: null,
      deals: null,
      relatedProject: null,
      isLoading: false,
      search: null,
      disableKv: false,
      isErrorDeal: false,
      isErrorStreet: false,
      isErrorHouseNr: false,
      isErrorAddress: false,
      statusesOpen: false,
      form: {
        appliedWatermarkSetId: "",
        foreignCountry: false,
        languageType: "estonian",
        addressType: "estonia",
        realAddress: {},
        status: "draft",
        linkedDeal: null,
        linkedDevelopment: null,
        linkedDeals: [],
        linkedDevelopments: [],
        selectedBuilding: '',
        dealType: "sale",
        estateType: "apartment",
        object: null,
        county: null,
        city: null,
        district: null,
        cityPart: null,
        coordinates: null,
        street: "",
        ehakCode: null,
        houseNumber: null,
        solidFuel: false,
        geothermalHeating: false,
        apartmentNumber: null,
        landPurpose: null,
        bathroomCount: null,
        showHouseNumber: true,
        showApartmentNumber: false,
        liquidFuel: false,
        centralHeating: false,
        cadastralNumber: null,
        airHeatPump: false,
        landArea: {
          m2: null,
          m2Selected: true,
          ha: null,
          haSelected: false,
        },
        isWatermarkApplied: true,
        estateNumber: null,
        windowType: null,
        brokerFee: null,
        buildingType: null,
        floorHeating: false,
        cadastralSize: null,
        roofType: null,
        centralGas: false,
        buildingStatus: null,
        localSewerage: false,
        centralSewerage: false,
        buildingPermit: false,
        heatingType: null,
        separateEntrance: false,
        buildYear: null,
        floors: null,
        gasHeating: false,
        floor: null,
        rooms: null,
        pricePerSquareMeter: null,
        bedrooms: null,
        trestle: false,
        goodsLift: false,
        generalSurfaceArea: null,
        furnaceHeating: false,
        price: "0",
        condition: null,
        ownershipForm: null,
        buildingMaterial: null,
        electricalHeating: false,
        detailPlan: false,
        sloganRU: null,
        sloganENG: null,
        sloganFI: null,
        descriptionRU: null,
        descriptionENG: null,
        descriptionFI: null,
        possibilityToChangeType: false,
        airWaterPump: false,
        energyLabel: null,
        expensesInSummer: null,
        expensesInWinter: null,
        pricePerDay: null,
        possibilityToChangePurpose: false,
        listingOwnerName: null,
        listingOwnerEmail: null,
        listingOwnerPhone: null,
        vatIncluded: false,
        depositPrice: null,
        balconyType: null,
        virtualTourLink: null,
        videoLink: null,
        sewer: null,
        freeParking: false,
        paidParking: false,
        balconySize: null,
        kitchenSize: null,
        garageType: null,
        garage: false,
        undergroundGarage: false,
        purpose: null,
        entranceType: null,
        parkingPlaces: null,
        rentIncluded: false,
        electricityPaid: false,
        subscriptionPaid: false,
        otherPrice: null,
        waterIncluded: false,
        summerPrice: null,
        gasIncluded: false,
        winterPrice: null,
        airheatPumpIncluded: false,
        industrialElectricity: false,
        parkingPossibility: false,
        electricity: false,
        marketplaces: [
          {
            name: "kv",
            isActive: true,
          },
          {
            name: "city24",
            isActive: true,
          },
          {
            name: "kv24",
            isActive: true,
          },
          {
            name: "www",
            isActive: true,
          },
        ],
        ancillaryBuilding: false,
        lift: false,
        parquet: false,
        packetWindows: false,
        ventilation: false,
        climaticDevice: false,
        forestIsNear: false,
        transportationIsNear: false,
        furniture: false,
        furnitureAbility: false,
        roomsSeparate: false,
        parkingOption: null,
        terrace: false,
        tv: false,
        basementFloor: false,
        water: false,
        closedYard: false,
        highCeilings: false,
        severalFloorsPlanning: false,
        cupBoard: false,
        showCadastralNumber: true,
        showEstateNumber: false,
        availableImmediately: true,
        dateAvailable: null,
        livingArea: null,
        readiness: null,
        limitations: null,
        detailedPlan: null,
        commercialLand: false,
        industrialLand: false,
        productionLand: false,
        forestLand: false,
        residentialLand: false,
        profityieldingLand: false,
        residentialLandApartment: false,
        otherUse: false,
        office: false,
        accomodation: false,
        tradeshop: false,
        services: false,
        warehouse: false,
        production: false,
        foodServices: false,
        notDefined: false,
        warehouseProduction: false,
        otherUsePossible: false,
        rentToOwn: false,
        additionalInfoLink: null,
        additionalInfoLinkENG: null,
        additionalInfoLinkRU: null,
        additionalInfoLinkFI: null,
        linkTitle: null,
        linkTitleENG: null,
        linkTitleRU: null,
        linkTitleFI: null,
        servicesInNeighbourhood: null,
        servicesInNeighbourhoodENG: null,
        servicesInNeighbourhoodRU: null,
        servicesInNeighbourhoodFI: null,
        balcony: false,
        closedBalcony: false,
        fullyGlazedBalcony: false,
        frenchBalcony: false,
        numberOfDesks: null,
        wardrobe: false,
        showCaseWindows: false,
        streetEntrance: false,
        rooftopApartment: false,
        industrialPowerSupply: false,
        well: false,
        sewerage: false,
        road: false,
        publicTransport: false,
        localWaterSupply: false,
        centralWaterSupply: false,
        coastLineLength: null,
        kitchen: false,
        electricalWiring: false,
        storeRoom: false,
        combinedHeating: false,
        attic: false,
        petsAllowed: false,
        meetingRoom: false,
        desks: false,
        shopWindow: false,
        pool: false,
        openedKitchen: false,
        fridge: false,
        fence: false,
        kitchenFurniture: false,
        washingMachine: false,
        broiler: false,
        boiler: false,
        shower: false,
        bath: false,
        sauna: false,
        wcBath: false,
        piping: false,
        firePlace: false,
        conditioner: false,
        internet: false,
        cableTv: false,
        telephone: false,
        securityDoor: false,
        staircaseLocked: false,
        neighbourhoodSecurity: false,
        guard: false,
        videoSecurity: false,
        reservoirName: null,
        reservoirDistance: null,
        gullyHole: null,
        stove: null,
        electricalStove: false,
        gasStove: false,
        ceramicStove: false,
        fireWoodStove: false,
        inductionStove: false,
        securitySystem: null,
        reservoir: null,
        neighbours: null,
        roads: null,
        location: null,
        building: null,
        amBroker: 1,
        additionalInfo: {
          reserved: false,
          tradable: false,
          doNotAllowToCopyListing: false,
          brokersNotAllowedToDisturb: false,
        },
        slogan: null,
        description: "",
        images: [],
        objects: '',
      },
      inAadress: null,
      //
      addressSearchContainer: null,
      labelElement: null,
      inputElement: null,
      dropdownContainer: null,
      clearButton: null,
      //
      tempCityValue: null,
      tempDistrictValue: null,
      //
      listingLink: 'project',
      buildingItems: [],
      relatedDevelopment: "",
      //
      isLinkListing: false,
      selectedItem: null,
      linkConfirmation: false,
    };
  },
  async created() {
    if (this.project) {
      this.chosenLinkedDealName = this.project.name;
      this.setRelatedDeal(this.project);
    }

    // SET COUNTIES - ideally fetch from API
    if (this.user.access.company._id === "xmwei_Rt9ryfpkxL4BL8") {
      locationsLatvia.locations.counties.forEach((location) => {
        this.counties.push({name: location.name, value: location.code})
      })
    } else {
      locationsEstonia.locations.counties.forEach((location) => {
        this.counties.push({ name: location.NIMETUS, value: location.KOOD });
      });
    }

    await this.getCompanyWatermarkSets();

    if (this.$route.params.id) {
      this.edit = true;
      await this.getListing();
    } else if (this.$route.query.dealId && this.$route.query.objectId) {
      this.addToProject = true;
      this.form.linkedDeal = this.$route.query.dealId;
      await this.getDeal();
    }
    await this.getActiveMarketplaces();
    this.$forceUpdate();
    if (this.$route.params.project) {
      let projectId = this.$route.params.project._id;
      await document.getElementById("item" + projectId).click();
      this.addToProject = true;
      this.deal = this.$route.params.project;
    }

    if (
      this.user.companyId === "S76LvflRF766oIt1NbvS" &&
      this.activeMarketplaces["kv"].active >=
        this.activeMarketplaces["kv"].total &&
      !this.edit
    ) {
      this.form.marketplaces[0].isActive = false;
      this.disableKv = true;
    }

    this.setLandAreaUnit();
  },
  mounted() {
    this.checkIfReferenced();
    this.closeMenu();
    if (!this.edit && !this.addToProject && (this.user.config && !this.user.access?.features?.modules?.projects?.enabled)) {
      this.setupAddressApi();
    }
  },

  computed: {
    ...mapGetters([
      "sidepanelDealsAdd",
      "user",
      "navigationAfterAddingProject",
      "listingsUpdater"
    ]),
    // SET CITIES
    cities() {
      let cities = null;
      if (this.county) {
        let citiesTemp = [
          { name: this.$t("City"), value: "city", _id: nanoid(16) },
        ];
        if (this.user.access.company._id === "xmwei_Rt9ryfpkxL4BL8") {
          locationsLatvia.locations.cities.forEach((location) => {
            if (location?.county?.code === this.county.value) {
              let name = location.name;
              let value = location.code;
              citiesTemp.push({ name, value });
            }
          })
        } else {
          locationsEstonia.locations.cities.forEach((location) => {
            if (location.YLEMKOMP_KOOD === this.county.value) {
              let name = location.NIMETUS;
              let value = location.KOOD;
              citiesTemp.push({ name, value });
            }
          });
        }
        cities = citiesTemp;
        this.enableCities();
      } else {
        cities = [{ name: this.$t("City"), value: "city", _id: nanoid(16) }];
        this.disableCities();
        this.disabledistricts();
      }
      return cities;
    },
    // SET DISTRICTS
    districts() {
      let districts = null;
      if (this.city) {
        let districtsTemp = [
          { name: this.$t("District"), value: "district", _id: nanoid(16) },
        ];
        locationsEstonia.locations.districts.forEach((location) => {
          if (location.YLEMKOMP_KOOD === this.city.value) {
            let name = location.NIMETUS;
            let value = location.KOOD;
            districtsTemp.push({ name, value });
          }
        });
        districts = districtsTemp;
        if (districts.length > 1) {
          this.enabledistricts();
        }
      } else {
        districts = [{ name: this.$t("District"), value: "district" }];
        this.disabledistricts();
      }
      return districts;
    },
    formattedAddress() {
      let parts = [];

      if (this.form.street) parts.push(this.form.street);
      if (this.form.houseNumber) parts.push(this.form.houseNumber);
      if (this.form.apartmentNumber) parts.push('-' + this.form.apartmentNumber);

      let secondaryParts = [];
      if (this.form.cityPart) secondaryParts.push(this.form.cityPart);
      if (this.form.district) secondaryParts.push(this.form.district);
      if (this.form.city) secondaryParts.push(this.form.city);
      if (this.form.county) secondaryParts.push(this.form.county);

      return parts.join(' ') + (secondaryParts.length > 0 ? ', ' : '') + secondaryParts.join(', ');
    }
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDeal",
      "setNavigationAfterAddingProject",
    ]),
    removeAddressObject() {
      this.object = null
      this.form.objects.pop()
    },
   /*  prefillAddress() {
      if (!this.edit) {
        //ADD
        console.log("NOT EDIT")
        this.form.address = ""
        //this.setAddressObject(this.development.address);
      } else {
       //EDIT
       console.log("EDIT LISTING ADDRESS plz", this.listing.address)
          this.form.address = this.listing.address;

          //this.setAddressObject(this.form.address);
      }
    }, */
    updateAddress(newAddress) {
      const countyObject = this.counties.find(c => c.name === newAddress.county || c.value === newAddress.countyCode);
      if (countyObject) {
        this.selectedCounty = countyObject;
      }

      this.tempCityValue = newAddress.city;
      this.tempDistrictValue = newAddress.district;

      this.form.street = newAddress.street;
      this.form.houseNumber = newAddress.houseNumber;
      this.form.county = newAddress.county;
      this.form.city = newAddress.city;
      this.form.district = newAddress.district;
      this.form.cityPart = newAddress.cityPart;
      this.form.cadastralNumber = newAddress.cadastralNumber;
      this.form.ehrNumber = newAddress.ehrNumber;
      this.form.estateNumber = newAddress.estateNumber;
      this.form.address = newAddress.address;
      this.form.landArea = newAddress.landArea;
      this.form.coordinates = {
        lat: newAddress.coordinates.lat,
        lng: newAddress.coordinates.lng
      };
      this.form.ehakCode = newAddress.ehakCode;
    },
    setObject(object) {
      let existingListings = null;
      let existingObjectId = null;

      if (this.isEdit) {
        if (this.form.addressType === "estonia") {
          existingListings = this.form.objects[0].listings;
          existingObjectId = this.form.objects[0]._id;
        } else if (this.form.addressType === "generic") {
          existingListings = this.form.objects[0].listings;
          existingObjectId = this.form.objects[0]._id;
        }
      }
      this.form.objects = [];
      this.object = object;
      this.form.objects.push(this.object);
      if (this.isEdit) {
        this.form.objects[0].listings = existingListings;
        this.form.objects[0]._id = existingObjectId;
      }
    },
    handleNewLink(item) {
      this.selectedItem = item;
      this.linkConfirmation = true;
    },
    handleLinkConfirm(link) {
      if (this.module == "development") {
        console.log(1)
        this.updateLinkedDeal(link);
        console.log(2)
        this.linkConfirmation = false;
        console.log(3)
        this.isLinkListing = false;
        console.log(4)
        this.deal.name = link.name;
        console.log(5)
        return;
      } else {
        let module = 'project'
        let body = {
          linkedId: link._id,
          listingId: this.form._id,
          module,
        };
        this.updateLinkedDeal(body);
        this.linkConfirmation = false;
        this.isLinkListing = false;
        this.deal.name = link.name;
        return;
      }
    },
    async updateLinkedDeal(body) {
      try {
        await axios.patch("/api/listing/update/linked-deal", body);
        this.toastSuccess(this.$t("ListingLinked"));
      } catch (error) {
        console.error("Error updating linked deal:", error);
      }
    },
    replaceSymbols(field) {
      let newDescription = this.form[field];

      const m2Match = newDescription.match(/m2(?!\d)/gi);
      const h2Match = newDescription.match(/h2(?!\d)/gi);
      const tabMatch = newDescription.match(/\t/g);

      // If a match is found, replace it with the corresponding symbol
      if (m2Match) {
        newDescription = newDescription.replace(/m2(?!\d)/gi, "m²");
      }
      if (h2Match) {
        newDescription = newDescription.replace(/h2(?!\d)/gi, "h²");
      }
      // Replace all tabs with space
      if (tabMatch) {
        newDescription = newDescription.replaceAll(/\t/g, " ")
      }
      this.form[field] = newDescription;
    },
    async getCompanyWatermarkSets() {
      const response = await axios.get("/api/company/watermarksets");
      if (response && response.data && response.data.length > 0) {
        this.companyWatermarkSets = [];
        for (const item of response.data) {
          this.companyWatermarkSets.push({ value: item._id, name: item.name });
        }

        this.form.appliedWatermarkSetId = this.companyWatermarkSets[0].value;
      }
    },
    async cancel() {
      if (!this.$router.back() && this.$route.params && this.$route.params.id) {
        await this.$router.push({
          name: "ListingsDetail",
          params: {
            listingId: this.$route.params.id,
            fromListingsAdd: true,
          },
        });
      } else {
        this.$router.back();
      }
    },
    isValidNumber(value) {
      return !(isNaN(value) || value === Infinity || value === "Infinity");
    },
    async changeWatermarkStatus(status) {
      this.form.isWatermarkApplied = status ? true : false;
    },
    formatNumberInput(value) {
      let outputValue = String(value);
      var regExp = /[a-zA-Z]/g;
      // if value has , replace with --> .
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join(".");
      }
      // if value has . or any letter --> delete last converted . or letter
      if (outputValue.split(".").length > 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      this.valueChanged++;

      return outputValue;
    },
    formatNumbersOnlyInput(value) {
      let outputValue = String(value);
      var regExp = /[a-zA-Z]/g;
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join("");
      }
      if (outputValue.split(".").length >= 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      this.valueChanged++;
      return outputValue;
    },
    numberInputFormatWholeNumber(input) {
      this.form[input] = this.formatNumbersOnlyInput(this.form[input]);
    },
    priceCalculate() {
      const reg = new RegExp("\\d*\\.?\\d*");
      switch (this.activePriceCalculatingInput) {
        case "price":
          // format input
          this.form.price = this.formatNumberInput(this.form.price);
          // validation
          if (/\s/.test(this.form.price)) {
            this.form.price = parseFloat(this.form.price.replace(/\s+/g, ""));
          }
          if (!this.isValidNumber(this.form.price)) {
            this.form.price = "";
          }
          if (!reg.test(this.form.price)) {
            return;
          }

          // calculation
          if (this.form.estateType !== "land") {
            if (
              this.form.generalSurfaceArea &&
              reg.test(this.form.generalSurfaceArea)
            ) {
              this.form.pricePerSquareMeter = (
                this.form.price / this.form.generalSurfaceArea
              ).toFixed(2);
            }
          } else {
            if (
              this.form.landArea.m2Selected &&
              reg.test(this.form.landArea.m2) &&
              this.isValidNumber(this.form.landArea.m2)
            ) {
              this.form.pricePerSquareMeter = (
                this.form.price / this.form.landArea.m2
              ).toFixed(2);
            } else if (
              reg.test(this.form.landArea.ha) &&
              this.isValidNumber(this.form.landArea.ha)
            ) {
              this.form.pricePerSquareMeter = (
                this.form.price / (this.form.landArea.ha * 10000)
              ).toFixed(2);
            }
          }

          break;
        case "surfaceArea":
          //formatting
          this.form.generalSurfaceArea = this.formatNumberInput(
            this.form.generalSurfaceArea
          );
          // validation
          if (!reg.test(this.form.generalSurfaceArea)) {
            return;
          }
          if (!this.isValidNumber(this.form.generalSurfaceArea)) {
            this.form.generalSurfaceArea = "";
          }
          // calculation
          if (this.form.price && reg.test(this.form.price)) {
            this.form.pricePerSquareMeter = (
              this.form.price / this.form.generalSurfaceArea
            ).toFixed(2);
            if (!this.isValidNumber(this.form.pricePerSquareMeter)) {
              this.form.pricePerSquareMeter = "";
            }
          }
          break;
        case "pricePerSquareMeter":
          // formatting
          this.form.pricePerSquareMeter = this.formatNumberInput(
            this.form.pricePerSquareMeter
          );
          // validation
          if (/\s/.test(this.form.pricePerSquareMeter)) {
            this.form.pricePerSquareMeter = parseFloat(
              this.form.pricePerSquareMeter.replace(/\s+/g, "")
            );
          }
          if (!this.isValidNumber(this.form.pricePerSquareMeter)) {
            this.form.pricePerSquareMeter = "";
          }
          if (!reg.test(this.form.pricePerSquareMeter)) {
            return;
          }
          // calculation
          if (this.form.estateType !== "land") {
            if (
              this.form.price &&
              reg.test(this.form.price) &&
              this.form.generalSurfaceArea &&
              reg.test(this.form.generalSurfaceArea)
            ) {
              this.form.price = (
                this.form.pricePerSquareMeter * this.form.generalSurfaceArea
              ).toFixed(2);
            }
          } else {
            if (
              this.form.landArea.m2Selected &&
              reg.test(this.form.landArea.m2) &&
              this.isValidNumber(this.form.landArea.m2)
            ) {
              this.form.price = (
                this.form.pricePerSquareMeter * this.form.landArea.m2
              ).toFixed(2);
            } else if (
              reg.test(this.form.landArea.ha) &&
              this.isValidNumber(this.form.landArea.ha)
            ) {
              this.form.price = (
                this.form.pricePerSquareMeter *
                (this.form.landArea.ha * 10000)
              ).toFixed(2);
            }
          }
          break;
        case "ha":
          // select landArea
          this.form.landArea.haSelected = true;
          this.form.landArea.m2Selected = false;
          // validation
          if (this.form.estateType !== "land") {
            break;
          }
          if (!reg.test(this.form.landArea.ha)) {
            return;
          }
          // calculation
          if (this.form.price && reg.test(this.form.price)) {
            this.form.pricePerSquareMeter = (
              this.form.price /
              (this.form.landArea.ha * 10000)
            ).toFixed(2);
            if (!this.isValidNumber(this.form.pricePerSquareMeter)) {
              this.form.pricePerSquareMeter = "";
            }
          }
          break;
        case "m2":
          // select landArea
          this.form.landArea.haSelected = false;
          this.form.landArea.m2Selected = true;
          // validation
          if (this.form.estateType !== "land") {
            break;
          }
          if (!reg.test(this.form.landArea.m2)) {
            return;
          }
          // calculation

          if (this.form.price && reg.test(this.form.price)) {
            this.form.pricePerSquareMeter = (
              this.form.price / this.form.landArea.m2
            ).toFixed(2);
            if (!this.isValidNumber(this.form.pricePerSquareMeter)) {
              this.form.pricePerSquareMeter = "";
            }
          }
          break;
      }
    },
    openCadastalLink(cadastralNumber) {
      if (cadastralNumber) {
        window.open(`https://xgis.maaamet.ee/ky/${cadastralNumber}`, "_blank");
      } else {
        window.open(`https://xgis.maaamet.ee/ky/`, "_blank");
      }
    },
    openEhrNumberLink(ehrNumber) {
      if (ehrNumber) {
        window.open(
          `https://livekluster.ehr.ee/ui/ehr/v1/building/${ehrNumber}`,
          "_blank"
        );
      } else {
        window.open(
          "https://livekluster.ehr.ee/ui/ehr/v1/detailsearch/BUILDINGS_SEARCH",
          "_blank"
        );
      }
    },
    openELandRegisterLink(cadastralNumber, estateNumber) {
      if (!estateNumber && cadastralNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?lang=Est&kadasterAadress=&nimi=&regNrIsikuKood=${cadastralNumber}`,
          "_blank"
        );
      } else if (estateNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?kadasterAadress=&nimi=&regNrIsikuKood=${estateNumber}`,
          "_blank"
        );
      } else if (!estateNumber && !cadastralNumber) {
        window.open("https://kinnistusraamat.rik.ee/Avaleht.aspx", "_blank");
      }
    },
    setLandAreaUnit() {
      if (!this.form.landArea.m2 && !this.form.landArea.ha) {
        this.landAreaUnit = "m2";
        return;
      }
      if (!this.form.landArea.m2 && this.form.landArea.ha) {
        this.landAreaUnit = "ha";
        return;
      }
      if (this.form.landArea.m2 && !this.form.landArea.ha) {
        this.landAreaUnit = "m2";
        return;
      }
      if (this.form.landArea.m2Selected) {
        this.landAreaUnit = "m2";
        return;
      }
      if (this.form.landArea.haSelected) {
        this.landAreaUnit = "ha";
      }
    },
    async changeActiveUntil(changedListing, newDate) {
      newDate = this.formatDate(newDate);
      let body = {
        listing: changedListing,
        newDate: newDate,
      };
      await listingApi.updateListingActiveUntil(body);
      this.form.activeUntil = newDate;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    setShortTermRent() {
      this.isShortTermRent = !this.isShortTermRent;
      if (!this.isShortTermRent) {
        this.form.dealType = "rent";
      } else {
        this.form.dealType = "short_term_rent";
      }
    },
    formatDealType() {
      if (this.chosenLinkedDeal.dealType) {
        return this.$t(this.chosenLinkedDeal.dealType);
      }
    },
    openDeleteModal() {
      this.deleteModalOpen = true;
    },
    openStatusDropdown() {
      if (this.edit) {
        let button = document.getElementById("statusLabel");
        const rect = button.getBoundingClientRect();
        this.position.right = window.innerWidth - rect.right - 50;
        this.position.top = rect.top + window.scrollY - 50;
        this.statusesOpen = true;
      }
    },
    openBookingDetailsModal(listing) {
      this.bookedListing = listing;
      this.bookingDetailsModalOpen = true;
    },
    changeListingStatus(event) {
      this.statusesOpen = false;
      this.form.status = event.status;
      this.form.bookedUntil = event.bookedUntil;
      this.form.activeUntil = event.activeUntil;
      this.form.statusChangedDate = event.statusChangedDate;
      this.bookingUpdater++;
    },
    openDeletePermanentlyModal() {
      this.deletePermanentlyModalOpen = true;
    },
    async getActiveMarketplaces() {
      const response = await axios.get(`/api/user/${this.user._id}`);
      const userData = response.data.user;
      this.activeMarketplaces = {
        kv: {
          active: userData.kvActive,
          total: userData.kvTotal,
        },
        kv24: {
          active: userData.kv24Active,
          total: userData.kv24Total,
        },
        city24: {
          active: userData.city24Active,
          total: userData.city24Total,
        },

        www: {
          active: userData.wwwActive,
          total: userData.wwwTotal,
        },
      };
      /* const response = await axios.post("/api/listings/brokers");
      const listings = response.data.listings;
      const listingsByUser = listings.filter(
        (listing) => listing.creator.userId === this.user._id
      );
      this.activeMarketplaces["kv"] = 0;
      this.activeMarketplaces["kv24"] = 0;
      this.activeMarketplaces["city24"] = 0;
      this.activeMarketplaces["www"] = 0;
      listingsByUser.forEach((listing) =>
        listing.marketplaces.forEach((marketplace) => {
          if (marketplace.isActive) {
            if (!this.activeMarketplaces[marketplace.name]) {
              this.activeMarketplaces[marketplace.name] = 1;
            } else {
              this.activeMarketplaces[marketplace.name] += 1;
            }
          }
        })
      );*/
    },
    async deleteListing() {
      const response = await axios.post(`/api/listing/delete/${this.form._id}`);
      if (response.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.push({
          name: "Listings",
        });
        this.toastSuccess(
          this.$t("Listing") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    async deleteListingPermanently() {
      const response = await axios.post(
        `/api/listing/delete-permanently/${this.form._id}`
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.push({
          name: "Listings",
        });
        this.toastSuccess(
          this.$t("Listing") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    setupAddressApi() {
      // eslint-disable-next-line no-undef
      this.inAadress = new InAadress({
        container: "InAadressDiv",
        mode: "3",
        nocss: true,
        appartment: 0,
        ihist: 0,
        lang: "et",
        namespace: "listingAdd",
      });
      //let searchInput = null;
      const search = setInterval(() => {
        this.addressSearchContainer =
          document.getElementsByClassName("inads-input-div")[0];
        if (this.addressSearchContainer) {
          clearInterval(search);
          this.labelElement = document.createElement("label");
          //this.addressSearchContainer.classList.remove("required")
          this.inputElement = document.getElementsByClassName("inads-input")[0];
          this.dropdownContainer =
            document.getElementsByClassName("inads-result")[0];
          this.clearButton =
            document.getElementsByClassName("inads-input-clear")[0];
          this.setupClearButton();
          this.setupAddressSearchContainer();
          this.setupLabel();
          this.setupInput();
          this.setupDropdownContainer();
        }
      }, 15);

      document.addEventListener("addressSelected.listingAdd", async (e) => {
        this.addressSelected = true;
        this.form.cityPart = null;
        this.form.district = null;
        this.form.county = null;
        this.form.city = null;
        this.form.street = null;
        this.form.houseNumber = null;
        this.form.apartmentNumber = null;
        let aadress = e.detail[0].aadress;
        aadress = aadress.split(", ").reverse().join(", ");
        this.form.street = e.detail[0]?.liikluspind;
        this.form.houseNumber = e.detail[0]?.aadress_nr;
        this.form.county = e.detail[0]?.maakond;
        e.detail.forEach((param) => {
          if (param.liik == "CU") {
            this.form.cadastralNumber = param?.orig_tunnus;
          } else {
            this.form.estateNumber = param?.orig_tunnus;
          }
        });
        if (this.form.cadastralNumber) {
          const response = await axios.get(
            `https://geoportaal.maaamet.ee/url/xgis-ky.php?ky=${this.form.cadastralNumber}&out=json`,
            {
              withCredentials: false,
            }
          );
          let matchPattern = response.data[1].Registriosa.match(/\d+/g);
          if (matchPattern) {
            this.form.estateNumber = response.data[1].Registriosa;
          } else {
            this.form.estateNumber = null;
          }
          this.form.landArea.m2 = null;
          if (response.data[1].Pindala) {
            let area = response.data[1].Pindala;
            if (area.includes("m²")) {
              let number = response.data[1].Pindala.replace(/m²/g, "");
              this.form.landArea.m2 = number;
            } else if (area.includes("ha")) {
              let haArea = response.data[1].Pindala.replace(/ha/g, "");
              let number = haArea * 10000;
              this.form.landArea.m2 = number;
            }
          }
        }

        //this.form.cadastralNumber = e.detail[0]?.orig_tunnus;
        let asum = e.detail[0].asum;
        let asustusyksus = e.detail[0].asustusyksus;
        let kohanimi = e.detail[0].nimi;
        if (kohanimi.length > 1) {
          this.form.street = kohanimi;
        }
        if (
          !this.containsWord(asustusyksus, "linn") &&
          !this.containsWord(asum, "linn")
        ) {
          if (asum.length > 1 && asum === "Vanalinna asum") {
            this.form.district = asum;
          } else {
            this.form.cityPart = null;
            this.form.district = asustusyksus;
          }
        }
        if (this.containsWord(asustusyksus, " linn")) {
          this.form.city = asustusyksus.split(" linn")[0];
          this.form.district = null;
          this.form.cityPart = null;
        } else {
          if (this.containsWord(e.detail[0].omavalitsus, " linn")) {
            this.form.city = e.detail[0].omavalitsus.split(" linn")[0];
          } else {
            this.form.city = e.detail[0].omavalitsus;
          }
        }
        this.markerCoordinates.lat = e.detail[0].b;
        this.markerCoordinates.lng = e.detail[0].l;
        this.mapCoordinates.lat = e.detail[0].b;
        this.mapCoordinates.lng = e.detail[0].l;
        if (!e.detail[0].ehak) {
          this.form.ehakCode = e.detail[0].ehakov;
        } else {
          this.form.ehakCode = e.detail[0].ehak;
        }
        this.inAadress.setAddress(aadress);
        this.inAadress.hideResult();
        this.form.object = null;
        this.countiesDisabled = false;
        this.form.realAddress = {
          cadastralNumber: this.form.cadastralNumber,
          street: this.form.street,
          houseNumber: this.form.houseNumber,
          county: this.form.county,
          city: this.form.city,
          district: this.form.district,
          cityPart: this.form.cityPart,
        };
        this.selectAddressFields();
      });
    },
    focusInput() {
      this.activatePlaceholder();
    },
    async unfocusInput() {
      this.deactivatePlaceholder();
    },
    activatePlaceholder() {
      this.labelElement.style.fontSize = "11px";
      this.labelElement.style.height = "12px";
      this.labelElement.style.color = "#FF5C01";
      this.labelElement.style.lineHeight = "11px";
      //input
      this.inputElement.style.height = "24px";
      this.inputElement.focus();
      document.addEventListener("click", this.checkIfDropdownClicked);
    },
    deactivatePlaceholder() {
      this.labelElement.style.color = "#75787a";
      if (!this.inputElement.value) {
        this.setupInput();
        this.setupLabel();
      }
    },
    checkIfDropdownClicked(e) {
      if (this.isDropdownOpen()) {
        const paths = e.path || (e.composedPath && e.composedPath());
        paths.forEach((path) => {
          if (path.classList && path.classList.contains("inads-result")) {
            // not hidden
          } else {
            this.dropdownContainer.classList.add("hidden");
          }
        });
      }
    },
    isDropdownOpen() {
      return !this.dropdownContainer.classList.contains("hidden");
    },
    setupAddressSearchContainer() {
      this.addressSearchContainer.style.display = "flex";
      this.addressSearchContainer.style.flexDirection = "column";
      this.addressSearchContainer.style.justifyContent = "center";
    },
    setupLabel() {
      this.labelElement.innerHTML = this.$t("FindAddress");
      this.labelElement.style.fontSize = "14px";
      this.labelElement.style.transition = "0.2s";
      this.labelElement.style.color = "#75787a";
      this.labelElement.style.width = "calc(100% - 36px)";
      this.addressSearchContainer.insertBefore(
        this.labelElement,
        this.addressSearchContainer.firstChild
      );
    },
    setupInput() {
      this.inputElement.style.height = "0";
      this.inputElement.style.transition = "0.2s";
      this.inputElement.style.backgroundColor = "";
      this.inputElement.placeholder = "";
      this.inputElement.style.width = "calc(100% - 36px)";
    },
    setupDropdownContainer() {
      this.dropdownContainer.style.top = "0";
      this.dropdownContainer.style.width = "472px";
      this.dropdownContainer.style.margin = "0 auto";
      this.dropdownContainer.style.border = "none";
      this.dropdownContainer.style.boxShadow =
        "0 4px 8px rgb(0 0 0 / 4%), 0 1px 2px rgb(0 0 0 / 3%), 0 0 1px rgb(0 0 0 / 4%)";
      this.dropdownContainer.style.borderRadius = "8px";
    },
    setupClearButton() {
      this.clearButton.addEventListener("click", (e) => this.removeObject(e));
    },
    removeObject() {
      //this.aadressSearch.setAddress("");
      this.activatePlaceholder();
      this.addressSelected = false;
    },
    async getStatuses(listing) {
      let chosenObject = this.$route.params.id;
      const response = await axios.get(`/api/listing/${chosenObject}`);
      listing.marketplaces = response.data[0].marketplaces;
      return listing.marketplaces;
    },
    async getListing() {
      let chosenObject = this.$route.params.id;
      const response = await axios.get(`/api/listing/${chosenObject}`);
      this.form = response.data[0];
      this.form.isWatermarkApplied = response.data[0].isWatermarkApplied;
      if (this.form.foreignCountry) {
        this.form.addressType = 'generic'
      }
      this.rightDealType = this.form.dealType;
      this.rightEstateType = this.form.estateType;
      this.form.languageType = this.languageTypes.filter(type => type.isChosen).map(e => e.value)[0]
      this.estateTypes.forEach((estateType) => {
        if (estateType.value == this.rightEstateType) {
          estateType.isChosen = true;
        } else {
          estateType.isChosen = false;
        }
      });
      this.dealTypes.forEach((dealType) => {
        if (dealType.value == this.rightDealType) {
          dealType.isChosen = true;
        } else {
          dealType.isChosen = false;
        }
      });
      if (this.form.coordinates) {
        this.mapCoordinates = this.form.coordinates;
        this.markerCoordinates = this.form.coordinates;
      }
      this.selectAddressFields();
      await this.getDeal();
      if (this.form.dealType === "short_term_rent") {
        this.isShortTermRent = true;
      }
      if (
        this.companyWatermarkSets &&
        this.companyWatermarkSets.length > 0 &&
        !this.form.appliedWatermarkSetId
      ) {
        this.form.appliedWatermarkSetId = this.companyWatermarkSets[0].value;
      }
      this.updateHTML++;
    },
    openDeal(deal) {
      this.changeSidepanelStateDeal();
      this.$router
        .push({
          name: this.form.isNewDevelopment ? "DevelopmentDetail" : "ProjectGeneral",
          params: { id: deal._id },
        })
        .then(() => {});
    },
    openListingModal() {
      this.isLinkListing = true;
    },
    async getDeal() {
      if (!this.form.linkedDeal) return;
      let dealId = this.form.linkedDeal;
      const response = this.form.isNewDevelopment ? await axios.get(`/api/unit/name/${dealId}`) : await axios.get(`/api/project/${dealId}`);
      this.deal = response.data;
      if (this.form.isNewDevelopment) return;
      if (this.deal.objects[0].foreignCountry) {
        this.counties = [];
        locationsLatvia.locations.counties.forEach((location) => {
          this.counties.push({name: location.name, value: location.code})
          this.countiesDisabled = false;

        })
      }
      if (!this.deal.aml.activeBusiness && this.form.status === "sale") {
        this.form.status = "draft";
      }
      if (this.$route.query.objectId) {
        this.form.object = this.deal.objects.filter(
          (object) => object._id === this.$route.query.objectId
        )[0];
        this.form.street = this.form.object.street;
        this.form.houseNumber = this.form.object.houseNumber;
        this.form.cityPart = this.form.object.cityPart;
        this.form.district = this.form.object.district;
        this.form.city = this.form.object.city;
        this.form.county = this.form.object.county;
        this.selectAddressFields();
      }

      if (this.deal.addressType) {
        this.form.addressType = this.deal.addressType;
      }
     
    },
    selectAddressFields(reset = false) {
      if (this.form.county) {
        this.counties.forEach((county) => {
          if (county.name === this.form.county) {
            this.selectCounty(county, reset);
          }
        });
      }
      if (this.form.city) {
        this.cities.forEach((city) => {
          if (city.name === this.form.city) {
            this.citiesDisabled = false;
            this.selectCity(city);
          }
        });
      }
      if (this.form.district) {
        this.districts.forEach((district) => {
          if (district.name === this.form.district) {
            this.districtsDisabled = false;

            this.selectDistrict(district);
          }
        });
      }
    },
    selectCounty(county, reset = true) {
      if (this.edit || this.addToProject) {
        this.countiesDisabled = false;
      }
      console.log("HEREE", county)
      this.selectedCounty = county;
      if (county.value === "county") {
        this.form.county = null;
        this.form.city = null;
        this.county = null;
        this.city = null;
        this.cityPart = null;
        this.district = null;
        this.form.district = null;
        this.form.cityPart = null;
        this.citiesUpdater++;
      } else {
        this.form.county = county.name;
        this.county = county;
        if (reset) {
          this.form.city = null;
          this.form.district = null;
          this.form.cityPart = null;
          this.city = null;
          this.district = null;
          this.cityPart = null;
          this.selectedCity = null;
          this.selectedDistrict = null;
          this.citiesUpdater++;
        }
      }
      this.countiesUpdater++;
      this.districtsUpdater++;
    },
    selectCity(city) {
      this.selectedCity = city;
      if (city.value === "city") {
        this.form.city = null;
        this.city = null;
      } else {
        this.form.city = city.name;
        this.city = city;
      }
      this.selectedDistrict = null;
      this.citiesUpdater++;
      this.districtsUpdater++;
    },
    selectDistrict(newDistrict) {
      if (newDistrict.value === "district") {
        this.form.district = null;
        this.district = null;
      } else {
        this.district = newDistrict;
        this.form.district = newDistrict.name;
        this.form.ehakCode = newDistrict.value;
      }
      this.selectedDistrict = newDistrict;
    },
    enableCities() {
      this.citiesDisabled = false;
    },
    disableCities() {
      this.citiesDisabled = true;
    },
    enabledistricts() {
      this.districtsDisabled = false;
    },
    disabledistricts() {
      this.districtsDisabled = true;
    },

    onlyNumber($event) {
      // added keycode from 96 to 105 as numpad numbers (needs to be tested in keyboard with numpad)
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
        $event.preventDefault();
      }
    },
    onlyNumberAndComa($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 46 &&
        keyCode !== 44 &&
        (keyCode < 96 || keyCode > 105)
      ) {
        $event.preventDefault();
      }
    },
    chooseObject(object) {
      console.log(object)
      this.form.object = object._id;
      this.form.cityPart = object.cityPart;
      this.form.district = object.district;
      this.form.county = object.county;
      this.form.street = object.street;
      this.form.city = object.city;
      this.form.houseNumber = object.houseNumber;
      this.form.apartmentNumber = object.apartmentNumber;
      this.form.cadastralNumber = object.cadastralNumber;
      this.form.ehrNumber = object.ehrNumber;
      this.form.landArea.m2 = object.landArea;
      this.form.ehakCode = object.ehakCode;
      this.form.estateNumber = object.estateNumber;
      if (this.user.config && !this.user.access?.features?.modules?.projects?.enabled) this.inAadress.setAddress(object.address);

      this.objectSelectionOpen = false;
      this.countiesDisabled = false;
      this.form.realAddress = object;
      this.selectAddressFields();
    },
    openObjectSelection() {
      if (this.chosenLinkedDeal && this.chosenLinkedDeal.objects.length) {
        let button = document.getElementById("InAadressDiv");
        const rect = button.getBoundingClientRect();
        this.position.right = window.innerWidth - rect.right - 12;
        this.position.top = rect.top + window.scrollY + 60;
        this.objectSelectionOpen = true;
      }
    },
    openProjectAdd() {
      this.projectAddOpen = true;
      document.documentElement.style.overflow = "hidden";
    },
    closeProjectAdd() {
      this.projectAddOpen = false;
      document.documentElement.style.overflow = "visible";
    },
    calculateExpenses() {
      if (this.form.expensesInSummer) {
        this.form.expensesInSummer = String(this.form.expensesInSummer).replace(
          ",",
          "."
        );
      }
      if (this.form.expensesInWinter) {
        this.form.expensesInWinter = String(this.form.expensesInWinter).replace(
          ",",
          "."
        );
      }
    },
    calculateLandArea() {
      if (this.form.landArea) {
        this.form.landArea.m2 = this.form.landArea.replace(",", ".");
        this.form.landArea.ha = this.form.landArea.replace(",", ".");
      }

      if (this.form.estateType !== "land") return;

      let landarea =
        this.landAreaUnit === "m2"
          ? this.form.landArea.m2
          : this.form.landArea.ha * 10000;

      if (this.form.price) {
        this.form.pricePerSquareMeter = (this.form.price / landarea).toFixed(2);
      }

      if (
        isNaN(this.form.pricePerSquareMeter) ||
        this.form.pricePerSquareMeter == Infinity
      ) {
        this.form.pricePerSquareMeter = 0;
      }
    },
    calculateBalconySize() {
      if (this.form.balconySize) {
        this.form.balconySize = this.form.balconySize.replace(",", ".");
      }
    },
    calculateKitchenSize() {
      if (this.form.kitchenSize) {
        this.form.kitchenSize = this.form.kitchenSize.replace(",", ".");
      }
    },
    async calculateSquareMeterPrice(e) {
      if (this.form.landArea.m2 || this.form.landArea.ha) {
        if (e) {
          document.getElementById("land-area-ha").value = document
            .getElementById("land-area-ha")
            .value.toString()
            .replace(",", ".");
          this.form.landArea.ha = document.getElementById("land-area-ha").value;
        }
        if (this.landAreaUnit === "m2") {
          this.form.landArea.haSelected = false;
          this.form.landArea.m2Selected = true;
        } else if (this.landAreaUnit === "ha") {
          this.form.landArea.m2Selected = false;
          this.form.landArea.haSelected = true;
        }
      }

      if (this.form.price) {
        this.form.price = this.form.price.toString().replace(",", ".");
      }
      if (this.form.generalSurfaceArea) {
        this.form.generalSurfaceArea = this.form.generalSurfaceArea
          .toString()
          .replace(",", ".");
      }
      //should recheck this
      if (this.form.landArea) {
        if (this.form.landArea.m2) {
          this.form.landArea.m2 = this.form.landArea.m2
            .toString()
            .replace(",", ".");
        } else if (this.form.landArea.ha) {
          this.form.landArea.ha = this.form.landArea.ha
            .toString()
            .replace(",", ".");
        }
      }

      if (this.form.price) {
        if (this.form.generalSurfaceArea && this.form.estateType !== "land") {
          this.form.pricePerSquareMeter =
            parseFloat(this.form.price.replace(/\s+/g, "")) /
            parseFloat(this.form.generalSurfaceArea.replace(/\s+/g, ""));
        } else if (
          (this.form.landArea.m2 && this.form.estateType === "land") ||
          (this.form.landArea.ha && this.form.estateType === "land")
        ) {
          let landarea = null;
          if (this.form.landArea.m2Selected && this.form.landArea.m2) {
            landarea = this.form.landArea.m2.replace(/\s+/g, "") * 1;
          } else if (this.form.landArea.haSelected && this.form.landArea.ha) {
            landarea = this.form.landArea.ha.replace(/\s+/g, "") * 10000;
          }
          this.form.pricePerSquareMeter =
            parseFloat(this.form.price.replace(/\s+/g, "")) /
            parseFloat(landarea);

          if (this.edit) {
            await axios.patch("/api/listing", this.form);
          }
        }
      }

      if (
        this.form.pricePerSquareMeter &&
        this.form.pricePerSquareMeter % 1 !== 0
      ) {
        this.form.pricePerSquareMeter = parseFloat(
          this.form.pricePerSquareMeter
        );
        this.form.pricePerSquareMeter =
          this.form.pricePerSquareMeter.toFixed(2);
      }

      if (
        isNaN(this.form.pricePerSquareMeter) ||
        Math.ceil(this.form.pricePerSquareMeter) === 0 ||
        this.form.pricePerSquareMeter == Infinity
      )
        this.form.pricePerSquareMeter = 0;
    },
    calculatePrice() {
      if (this.form.pricePerSquareMeter) {
        this.form.pricePerSquareMeter = this.form.pricePerSquareMeter
          .toString()
          .replace(",", ".");
      }

      if (this.form.pricePerSquareMeter) {
        if (this.form.generalSurfaceArea && this.form.estateType !== "land") {
          this.form.price =
            parseFloat(this.form.pricePerSquareMeter) *
            parseFloat(this.form.generalSurfaceArea);
        } else if (this.form.landArea && this.form.estateType === "land") {
          let landarea =
            this.landAreaUnit === "m2"
              ? this.form.landArea.m2
              : this.form.landArea.ha * 10000;
          this.form.price =
            parseFloat(this.form.pricePerSquareMeter) * parseFloat(landarea);
        }
      }

      if (this.form.price && this.form.price % 1 != 0)
        this.form.price = parseFloat(this.form.price);
      this.form.price = this.form.price.toFixed();

      if (
        isNaN(this.form.price) ||
        Math.ceil(this.form.price) === 0 ||
        this.form.price == Infinity
      )
        this.form.price = 0;
    },
    async checkIfReferenced() {
      let ref = this.$route.params.referenceDealId;
      if (ref) {
        const response = await axios.get(`/api/project/${ref}`);
        let refDeal = response.data[0];
        this.form.linkedDeal = refDeal._id;
        this.chosenLinkedDealName = refDeal.name;
        this.chosenLinkedDeal = refDeal;
      }
    },
    setMarker(e) {
      this.markerCoordinates = e.latLng;
    },
    updateMarker(location) {
      this.markerCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },
    containsDot($event) {
      if ($event.key === ",") {
        $event.preventDefault();
      }
    },
    async handleNewDeal(d) {
      await this.setNavigationAfterAddingProject(true);
      const newListingId = d.objects[0].listings[0];
      await this.$router.push({
        name: "ListingsChange",
        params: { id: newListingId },
      });
      await this.$router.go(0);
      /*await this.getDeals();
      this.form.linkedDeal = d._id;
      this.form.linkedDeals.push(d._id);
      this.chosenLinkedDeal = d;
      this.chosenLinkedDealName = d.name;
      this.closeDealMenu += 1;
      this.projectAddOpen = false;

      this.chooseObject(d.objects[0]);
      if (d.estateType && d.estateType !== "") {
        this.estateTypes.forEach((estateType) => {
          if (estateType.value === d.estateType) {
            this.form.estateType = estateType.value;
            estateType.isChosen = true;
          } else {
            estateType.isChosen = false;
          }
        });
      }

      this.dealTypes.forEach((dealType) => {
        dealType.isChosen = false;
        if (d.dealType === "Sale" && dealType.value === "sale") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
        if (d.dealType === "LeaseOut" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
        if (d.dealType === "Lease Out" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
      });
      this.closeDealMenu += 1;*/
    },
    closeMenu() {
      this.closeDealMenu += 1;
      //document.documentElement.style.overflow = "hidden";
    },
    clearDeal() {
      this.form.linkedDeal = null;
      this.form.linkedDeals = [];
      this.chosenLinkedDeal = null;
      this.form.addressType = "estonia";
      this.chosenLinkedDealName = null;
      this.form.object = null;
      this.form.cityPart = null;
      this.form.district = null;
      this.form.county = null;
      this.form.city = null;
      this.form.street = null;
      this.form.houseNumber = null;
      this.form.apartmentNumber = null;
      this.form.cadastralNumber = null;
      this.countiesDisabled = true;
      this.disableCities();
      this.disabledistricts();
      this.selectedCounty = null;
      this.selectedCity = null;
      this.selectedDistrict = null;
      this.countiesUpdater++;
      this.citiesUpdater++;
      this.districtsUpdater++;
      this.form.dealType = "sale";
      this.isShortTermRent = false;
      if (this.user.config && !this.user.access?.features?.modules?.projects?.enabled) {
        this.inAadress.setAddress("");
        let searchInput =
          document.getElementsByClassName("ui-input-search")[0].childNodes[0];
        searchInput.disabled = true;
        let searchInputBox =
          document.getElementsByClassName("ui-filterable")[0];
        searchInputBox.style.opacity = "0.5";
      }
    },
    formatDate(e) {
      var allDate = e.split(" ");
      var thisDate = allDate[0].split("-");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");
      return newDate;
    },
    parseBuildings(buildings) {
      const buildingItems = [];
      buildings.forEach((building) => {
        if (building.buildingName) {
          buildingItems.push({
            name: building.buildingName,
            value: building._id,
          });
        } else {
          buildingItems.push({
            name: building.addressObjects[0].address,
            value: building._id,
          });
        }
      });
      return buildingItems;
    },
    setRelatedDevelopment(dev) {
      this.clearDeal();
      this.buildingItems = this.parseBuildings(dev.buildings);
      console.log('this.buildings: ', this.buildings)
      this.form.linkedDevelopment = dev._id;
      this.form.linkedDevelopments.push(dev._id);
      this.chosenLinkedDeal = dev;

      this.form.cityPart = dev.address.cityPart;
      this.form.district = dev.address.district;
      this.form.county = dev.address.county;
      this.form.city = dev.address.city;
      this.form.street = dev.address.street;
      this.form.houseNumber = dev.address.houseNumber;
      this.form.cadastralNumber = dev.address.cadastralNumber;

      this.chosenLinkedDealName = dev.projectName;

      if (dev.assetClass && dev.assetClass !== "") {
        this.estateTypes.forEach((estateType) => {

          if (estateType.value ===  dev.assetClass) {
            this.form.estateType = estateType.value;
            estateType.isChosen = true;
          } else {
            estateType.isChosen = false;
          }
        });
      }
      this.form.dealType = dev.assetType;
      this.dealTypes.forEach((dealType) => {
        dealType.isChosen = false;
        if (dev.assetType === "sale" && dealType.value === "sale") {

          dealType.isChosen = true;
        }
        if (dev.assetType === "LeaseOut" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
        if (dev.assetType === "Lease Out" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
      });
      if (dev.address.coordinates) {
        this.markerCoordinates = dev.address.coordinates;
        this.mapCoordinates = dev.address.coordinates;
      }

      this.closeDealMenu += 1;
    },
    setRelatedDeal(ev) {
      this.clearDeal();
      if (ev.addressType) {
        this.form.addressType = ev.addressType;
      }
      this.form.linkedDeal = ev._id;
      this.form.linkedDeals.push(ev._id);
      this.chosenLinkedDeal = ev;

      this.chosenLinkedDealName = ev.name;
      ev.objects[0].apartmentNumber = ev.apartmentNumber;
      this.chooseObject(ev.objects[0]);
      if (ev.estateType && ev.estateType !== "") {
        this.estateTypes.forEach((estateType) => {
          if (estateType.value === ev.estateType) {
            this.form.estateType = estateType.value;
            estateType.isChosen = true;
          } else {
            estateType.isChosen = false;
          }
        });
      }

      this.dealTypes.forEach((dealType) => {
        dealType.isChosen = false;
        if (ev.dealType === "Sale" && dealType.value === "sale") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
        if (ev.dealType === "LeaseOut" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
        if (ev.dealType === "Lease Out" && dealType.value === "rent") {
          this.form.dealType = dealType.value;
          dealType.isChosen = true;
        }
      });
      if (ev.objects[0].coordinates) {
        this.markerCoordinates = ev.objects[0].coordinates;
        this.mapCoordinates = ev.objects[0].coordinates;
      }
      if (ev.objects[0].foreignCountry) {
        this.form.foreignCountry = true;
      }

      //BEFORE REWORK TO ONE OBJECT
      //-----------------------------------
      // let searchInput =
      //   document.getElementsByClassName("ui-input-search")[0].childNodes[0];
      // searchInput.disabled = false;
      // let searchInputBox = document.getElementsByClassName("ui-filterable")[0];
      // searchInputBox.style.opacity = "1";
      this.closeDealMenu += 1;
    },
    putImages(e) {
      this.form.images = e;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    /* async getDeals() {
      let response = await axios.get("/api/project/filtered");
      this.deals = response.data.deals;
      if (this.$route.params.id) {
        this.deals.forEach((el) => {
          if (el._id == this.$route.params.id) {
            this.form.linkedDeal = el._id;
            this.form.linkedDeals.push(el._id);
          }
        });
      }
    }, */
    hasHistory() {
      return document.referrer && document.referrer !== window.location.href;
    },
    async saveListing(listing, exitPage) {
      if (this.isLoading) return;
      this.isLoading = true;

      if (!this.form.price && this.form.price !== 0) {
          this.errors = true;
          window.scroll({ top: 1270, left: 0, behavior: "smooth" });
          this.isLoading = false;
          return;
      }
      if ((this.form.slogan && this.form.slogan.length) > 64 || (this.form.sloganRU && this.form.sloganRU.length > 64) || (this.form.sloganENG && this.form.sloganENG.length > 64) || (this.form.sloganFI &&this.form.sloganFI.length > 64)) {
        this.sloganLengthError = true
        window.scroll({ top: 3400, left: 0, behavior: "smooth" });
        this.toastError( this.$t("SloganMustBeMax64Chars"));
        this.isLoading = false;
        return;
      }
      this.sloganLengthError = false
      this.errors = false;

      this.form.realAddress = this.form.realAdress || {
          cadastralNumber: this.form.cadastralNumber,
          street: this.form.street,
          houseNumber: this.form.houseNumber,
          apartmentNumber: this.form.apartmentNumber,
          county: this.form.county,
          city: this.form.city,
          district: this.form.district,
          cityPart: this.form.cityPart,
      };

      const isHouseNrFilled = /^\d+$/.test(this.form.houseNumber);
      this.isErrorHouseNr = !isHouseNrFilled;

      const addressField2 = this.form.realAddress;
      const addressField = this.form.realAddress.address;
      const addressFilled = addressField || Object.values(addressField2).some(item => item != null && item !== "");
      this.isErrorAddress = !addressFilled;

      const dealFilled = this.form.linkedDeals.length > 0;
      this.isErrorDeal = !dealFilled;

      if ((dealFilled && addressFilled) || (this.user.config && addressFilled)) {
        if (!this.edit) listing._id = nanoid(20);
        if (!this.user.config || (this.user.config && this.user.access?.features?.modules?.projects?.enabled)) await axios.get(`/api/project/${this.form.linkedDeals[0]}`);
        listing.coordinates = this.markerCoordinates;

        let saveResponse;

        if (this.listingLink == 'development') {
          let body = {
            unit: this.form,
            buildingId: this.form.selectedBuilding,
            listingId: this.listingId
          };
          saveResponse = await axios.post(`/api/development/unit/add/${this.chosenLinkedDeal._id}`, body);
        } else {
          saveResponse = !this.edit ? await axios.post("/api/listing", listing) : await axios.patch("/api/listing", listing);
        }

        if (saveResponse.data === 0) {
            this.toastError(this.edit ? this.$t("ErrorUpdating") : this.$t("ErrorCreating"));
            this.isLoading = false;
            return;
        }

        await this.toastSuccess(this.$t("Listing") + " " + (this.edit ? this.$t("Updated") : this.$t("Created")).toLowerCase());

        if (exitPage) {
          if (this.navigationAfterAddingProject) {
            this.setNavigationAfterAddingProject(false).then(() => {
              this.$router.push({ name: "ListingsDetail", params: { listingId: saveResponse.data._id, fromListingsAdd: true } });
            });
          } else if (this.hasHistory()) {
            this.$router.back()
          } else {
            this.$router.push({ name: "ListingsDetail", params: { listingId: saveResponse.data._id, fromListingsAdd: true } });
          }
        } else {
          this.$router.push({ name: "ListingsChange", params: { id: saveResponse.data._id } });
          this.edit = true;
          this.getListing();
        }

        this.isLoading = false;
      }
    },
    chooseBox1(choice) {
      this.dealTypes.forEach((deal) => {
        if (deal.value == choice) {
          deal.isChosen = true;
        } else {
          deal.isChosen = false;
        }
      });
    },
    chooseBox2(choice) {
      this.estateTypes.forEach((type) => {
        if (type.value == choice) {
          type.isChosen = true;
        } else {
          type.isChosen = false;
        }
      });
      this.updateHTML++;
    },
    chooseBox3(choice) {
      this.form.languageType = choice;
      this.languageTypes.forEach((type) => {
        if (type.value == choice) {
          type.isChosen = true;
        } else {
          type.isChosen = false;
        }
      });
      this.updateHTML++;
    },
    chooseSection2(choice) {
      this.sectionTypes.forEach((type) => {
        if (type.value == choice) {
          type.isChosen = true;
        } else {
          type.isChosen = false;
        }
      });
      this.updateHTML++;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-edit-outputs-wrapper {
  height: 48px;
  margin-bottom: 32px;
}

.header {
  display: flex;
  justify-content: flex-start;
  padding-left: 156px;
  padding-right: 88px;
  padding-top: 40px;
}

.content {
  display: block;
  padding-left: 156px;
  padding-right: 88px;
  padding-bottom: 48px;
  background-color: #f4f5f7;
  margin-top: 24px;
  min-width: 760px;
  max-width: 1204px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 960px;
}

.section-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 32px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 960px;
  min-height: 220px;
}

.additional-info-gaps {
  justify-content: space-between;
  width: 50%;
}

.subheading {
  margin-top: 32px !important;
}

.subheading-autosearch-address {
  margin-top: 32px !important;
  max-width: 896px;
}

.first-general-column {
  padding: 0px;
}

.second-general-column {
  padding: 0px;
}

.example {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a;
}

.subheading-additionalInfo {
  padding-bottom: 12px;
  width: 25%;
}

.autocomplete-row {
  width: 100%;
  flex-direction: column;
}

.autocomplete-item {
  z-index: 4;
  width: 408px;
}

.autocomplete-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin-top: 32px !important;
  position: static;
  width: 896px;
  left: 32px;
  top: 88px;
  background: #f4f5f7;
  border-radius: 8px;

  img {
    margin-right: 8px;
  }
}

.deal-name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.autocomplete-frame-address {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
}

.autocomplete-address {
  width: 3rem !important;
}

#map {
  padding-top: 4px;
}

.balcony-row {
  width: 432px;
  padding-top: 26px;
  //margin-top: 30px;
}

.kitchen-row {
  width: 437px;
  margin-bottom: -16px;
}

.ownership-row {
  max-width: 230px;
}

#detailView {
  padding-bottom: 24px;
  height: 40px;
}

.line-breaker {
  width: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
}

#boardView {
  margin-left: 24px;
}

#detailView,
#boardView {
  cursor: pointer;
  margin-top: 12px !important;
}

.active-view {
  border-bottom: 2px solid black;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  margin-left: 16px;
}

.statusLabel.booked-label {
  border-radius: 4px 0 0 4px;
}

.booked-info-label {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  min-height: unset;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

.booking-until-date {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.box-wrapper {
  gap: 8px;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
}

.date-available-row {
  width: 432px;
  height: 52px !important;
  margin-top: 12px !important;
}

.big-box1,
.big-box2 {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 16px 20px;

  background: #ffffff;

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
}

.big-box1-white {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 16px 20px;

  background: #ffffff;
  color: white !important;

  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
}

.big-box2-white {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 32px 40px;
  color: white !important;

  border-radius: 8px;
  cursor: pointer;
}

.icon-box {
  padding: 16px;
  padding-left: 16px;

  .row {
    //justify-content: left;
  }
}

.big-box2-image {
  z-index: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: transparent;
}

.big-box3-image {
  z-index: 0;
  width: 16.67px;
  height: 16.67px;
  margin-right: 8px;
  background: transparent;
}

.big-box2-image2 {
  z-index: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  align-items: center;
  background: transparent;
}

.big-box2-image-white {
  background: transparent;
  filter: invert(96%) sepia(100%) saturate(18%) hue-rotate(299deg)
    brightness(105%) contrast(103%);
}

.active-box {
  border: 1px solid #ff5c01;
  background: #ff5c01;
}

.divider {
  width: 100%;
  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
}

.floor-items {
  gap: 12px;
  flex-wrap: nowrap;
}

.circle-number {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  background: #000;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding-top: 2px;
  font-size: 12px;
}

/*.short-term-rent-option-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}*/
.no-active {
  //width: 276px !important;
  height: 52px !important;
}

.deal-name-item {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.no-active2 {
  max-width: 210px !important;
  height: 52px !important;
}

::v-deep .delete {
  padding: 0;
}

::v-deep .plus {
  padding-top: 1px;
}

.house-numbers {
  padding: 0px;
}

.cadastral-numbers {
  padding: 0px;
}

.no-active3 {
  width: 800px !important;
}

.additional-first-row {
  padding-left: 0px;
  padding-top: 32px;
}

/* ::v-deep .inaadressSearchContainer {
  background: white !important;
} */
.additional-second-row {
  padding-top: 32px;
  padding-left: 18px;
}

.additional-third-row {
  margin-top: 6px;
  padding-left: 0px;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  max-width: 960px;
  height: 104px;
  gap: 8px;
  padding: 32px 32px 32px;
  background-color: white;
  border-radius: 8px;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.floor-row {
  height: 52px;
  width: 144px;
  width: 30%;
}

/*  ::v-deep .inaadressSearchContainer {
  background: #f4f5f7 !important;
}  */

::v-deep .vue-map {
  border-radius: 8px;
}

.content-small-green {
  color: #0aaf60;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-pink {
  color: #f81ca0;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-font {
  background: #f2ffe0;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pink-font {
  background: #fff2f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.price-per-unit-text {
  align-self: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #000000;
}

::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}

::v-deep #InAadressDiv {
  z-index: 5;
}

::v-deep .inads-input-div {
  border: 1px solid rgb(230, 232, 236);
  border-radius: 8px;
  cursor: text;
  height: 52px !important;
  padding: 7px 11px;
  display: flex;
  margin-top: 0;
}

::v-deep .inads-input-div label {
  font-family: Inter, sans-serif !important;
  line-height: 20px;
  cursor: text;
}

::v-deep .inads-input {
  border: none;
  height: 0;
  padding: 0 !important;
  position: unset !important;
}

::v-deep .inads-copyright {
  display: none !important;
}

::v-deep .inads-input-search {
  display: none;
}

::v-deep .inads_spinner {
  display: none;
}

::v-deep .inads-input-clear {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/close_grey.svg");
  background-size: 24px 24px;
}

::v-deep .inads-result li:first-child {
  border-radius: 8px 8px 0 0;
}

::v-deep .inads-result li:only-child {
  border-radius: 8px;
}

::v-deep .inads-result li:last-child {
  border-radius: 0 0 8px 8px;
}

::v-deep .inads-result li:hover {
  background-color: #f4f5f7;
  color: black !important;
}

::v-deep .inads-result li {
  padding: 7px 11px;
}

::v-deep .inads-result li span {
  font-family: Inter, sans-serif !important;
  font-size: 14px;
}

.address-div {
  width: 100%;
  height: 52px !important;
}

.address-selected {
  padding-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  min-width: 432px;
  max-width: 432px;
  height: 52px;
  padding: 0px !important;
  padding-bottom: 0px !important;

  &:focus {
    outline: none !important;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 999;
  left: 198px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 16px;
  -webkit-min-logical-width: calc(100% - 16px);
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 16px;
}

/* input#session-date {
    display: inline-block;
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */
.image-uploader {
  //min-width: 896px;
  min-height: 98px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #75787a;
  letter-spacing: 0em !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

#textarea {
  width: 100%;
  height: 182px;
}

.cancel {
  background-color: transparent;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  padding: 12px 12px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  align-items: center;
  display: flex;
  font-family: Inter;
}

.delete-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .gray-border {
  .ui-filterable {
    border: 2px solid #e6e8ec !important;
  }
}

::v-deep .ui-page-theme-a {
  background-color: transparent !important;
}

::v-deep
  .ui-popup-container
  ::v-deep
  .ui-popup-hidden
  ::v-deep
  .ui-popup-truncate {
  display: none !important;
}

::v-deep
  .inAadressInfo
  .ui-page-theme-a
  .ui-popup
  .ui-body-inherit
  .ui-overlay-shadow
  .ui-corner-all {
  background: #f4f5f7 !important;
}

::v-deep .black--text {
  color: #ff5c01 !important;
  caret-color: #ff5c01 !important;
}

::v-deep .theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ff5c01 !important;
}

.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;

  p {
    color: white;
  }
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
}

::v-deep .active-marketplaces .theme--light.v-input {
  color: #e6e8ec !important;
}

.clickable {
  cursor: pointer;
}

::v-deep
  .active-marketplaces
  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
  .v-icon {
  color: rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
}

.link-icon {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.object-address-container {
  width: 100%;
}

::v-deep .object-address-container .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../assets/images/search.svg");
  }
}

::v-deep .object-address-container .inaadressSearch,
::v-deep .object-address-container .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }

  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../assets/images/Dollar.svg");
    }
  }
}

.listing-images-updating {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fff0f0;
  padding: 12px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #FF1E24;
  }
}
</style>
